/*
	Bitstream Map Editor
	マップ編集機能を追加する
 */
(function() {
	"use strict";

	const $ = jQuery;

	var GM, simpleMapStyle, defaults = {
		lat: 36.578081
		, lng: 136.647835
		, zoom: 16
	};

	function BSMapEditor(dom) {
		var me = this;
		me.dom = $(dom);
		me.mapDom = null;
		me.map = null;
		me.marker = null;
		me.geocoder = null;
		me.addressEl = [];
		me.latEl = null;
		me.lngEl = null;
		me.zoomEl = null;
		me.controlsEl = null;
		me.geocodeBtn = null;
		me.removeBtn = null;
		me.init();
	}

	BSMapEditor.prototype = {

		init: function() {
			var me = this, attr, lat, lng, zoom;

			me.geocoder = new GM.Geocoder;

			// 操作ボタン
			me.controlsEl = $("<div>").addClass("bs-map-editor-controls").appendTo(me.dom);

			me.geocodeBtn = $("<button>").attr("type", "button").addClass("btn btn-sm btn-default hide").text("住所から地図を検索")
			.appendTo(me.controlsEl)
			.on(BS.Event.Tap, $.proxy(me.geocodeAddress, me));

			me.removeBtn = $("<button>").attr("type", "button").addClass("btn btn-sm btn-danger hide").text("地図を削除")
			.appendTo(me.controlsEl)
			.on(BS.Event.Tap, $.proxy(me.removeMap, me));

			me.errorE = $("<span>").addClass("bs-map-editor-error hide").appendTo(me.controlsEl);

			// 地図を表示する要素
			me.mapDom = $("<div>").addClass("bs-map-editor-map hide").appendTo(me.dom);

			// 住所を入力するフォーム
			attr = me.dom.attr("data-address");
			if (attr !== null) {
				attr.split(",").forEach(function(name) {
					var el = me.fc(name);
					if (el) {
						me.addressEl.push(el);
					}
				});
				if (me.addressEl.length > 0) {
					me.geocodeBtn.removeClass("hide");
				}
			}

			// 緯度・経度を入出力するフォーム
			attr = me.dom.attr("data-lat");
			if (attr !== null) {
				me.latEl = me.fc(attr);
			}

			attr = me.dom.attr("data-lng");
			if (attr !== null) {
				me.lngEl = me.fc(attr);
			}

			if (!me.latEl || !me.lngEl) {
				throw new Error("MapEditor: Lat and lng form does not exists.");
			}

			// 地図縮尺を入出力するフォーム
			attr = me.dom.attr("data-zoom");
			if (attr !== null) {
				me.zoomEl = me.fc(attr);
			}

			me.dom.removeClass("hide");

			// 座標が設定されていれば地図を表示する
			lat = me.latEl.val();
			lng = me.lngEl.val();
			zoom = me.zoomEl ? me.zoomEl.val() - 0 : defaults.zoom;
			if (lat !== "" && lng !== "") {
				me.showMap(lat, lng, zoom);
			}
		},

		// 地図を初期化する
		initMap: function(lat, lng, zoom) {
			var me = this;

			me.mapDom.removeClass("hide");
			me.removeBtn.removeClass("hide");

			// 地図を初期化
			me.map = new GM.Map(me.mapDom[0], {
				mapTypeId: GM.MapTypeId.ROADMAP
				, center: new GM.LatLng(lat, lng)
				, zoom: zoom
				, mapTypeControl: false
				, scrollwheel: false
				, streetViewControl: false
			});

			// マップタイプを追加して設定
			me.map.mapTypes.set("simple_map", simpleMapStyle);
			me.map.setMapTypeId("simple_map");

			// 地図のイベントハンドラを設定
			GM.event.addListener(me.map, "drag", $.proxy(me.saveValues, me));
			GM.event.addListener(me.map, "dragend", $.proxy(me.saveValues, me));
			GM.event.addListener(me.map, "zoom_changed", $.proxy(me.saveValues, me));

			// 位置を示すマーカー
			me.marker = new GM.Marker({
				map: me.map
				, position: new GM.LatLng(lat, lng)
				, title: "ドラッグして移動することができます"
				, draggable: true
				, bouncy: false
			});
			GM.event.addListener(me.marker, "mouseup", $.proxy(me.saveValues, me));
			GM.event.addListener(me.marker, "mousedown", $.proxy(me.saveValues, me));
			GM.event.addListener(me.marker, "drag", $.proxy(me.saveValues, me));
		},

		showMap: function(lat, lng, zoom) {
			var me = this, pos;

			if (!me.map) {
				me.initMap(lat, lng, zoom);
			}
			else {
				pos = new GM.LatLng(lat, lng, zoom);
				me.map.setCenter(pos);
				me.map.setZoom(zoom);
				me.marker.setPosition(pos);
			}
		},

		removeMap: function() {
			var me = this;

			me.mapDom.addClass("hide").empty();
			me.removeBtn.addClass("hide");
			me.map = null;
			me.marker = null;
			me.latEl.val("");
			me.lngEl.val("");
			if (me.zoomEl) {
				me.zoomEl.val("");
			}
		},

		saveValues: function() {
			var me = this, pos;
			pos = me.marker.getPosition();
			me.latEl.val(pos.lat());
			me.lngEl.val(pos.lng());
			if (me.zoomEl) {
				me.zoomEl.val(me.map.getZoom());
			}
		},

		geocodeAddress: function() {
			var me = this, address;

			me.setError();

			address = "";
			me.addressEl.forEach(function(el) {
				address += el.val();
			});
			if (address === "") {
				me.setError("住所が入力されていません");
				return;
			}

			me.geocoder.geocode({ address: address }, function(results, status) {
				var GS = GM.GeocoderStatus;
				switch (status) {
				case GS.OK:
					var location = results[0].geometry.location;
					me.showMap(location.lat(), location.lng(), defaults.zoom);
					me.saveValues();
					break;
				case GS.ZERO_RESULTS:
					me.setError("お探しの住所は見つかりませんでした");
					break;
				case GS.ERROR:
					me.setError("ネットワークエラー");
					break;
				case GS.OVER_QUERY_LIMIT:
					me.setError("Geocoding API の使用量制限のため処理を実行できませんでした");
					break;
				default:
					me.setError("エラーが発生したため処理を実行できませんでした");
					console.log("Geocoding error: " + status);
					break;
				}
			});
		},

		setError: function(message) {
			var errorE = this.errorE;
			if (message == null) {
				errorE.addClass("hide");
			} else {
				errorE.removeClass("hide").text(message);
			}
		},

		// Get form control
		fc: function(name) {
			var el = $("[name='" + name + "'], [name*='[" + name + "]']");
			if (el.length > 0) {
				return el;
			}
			return null;
		},
	};

	function initAll() {
		BS.GoogleMaps.ready(function mapEditorInitializer() {
			GM = google.maps;

			simpleMapStyle = new google.maps.StyledMapType([
				{
					featureType: "poi",
					elementType: "labels",
					stylers: [
						{ visibility: "off" }
					]
				}
			], { name: "Simple Map" });

			$(function() {
				$(".bs-map-editor").each(function() {
					var el = $(this);
					// 二重に初期化しないようにクラスを付与して判断する
					if (!el.hasClass("bs-map-editor-initialized")) {
						el.addClass("bs-map-editor-initialized");
						new BSMapEditor(this);
					}
				});
			});
		});
	}

	BS.MapEditor = {
		init: initAll
	};

	initAll();

})();
