// ブラウザが Exif orientation に基づいて画像を自動回転する場合は true を返す
zzl.uploader.supportImageOrientation = function() {
	var matched,
	vendor = navigator.vendor,
	appVersion = navigator.appVersion,
	userAgent = navigator.userAgent;

	// 次のブラウザは image-orientation 対応
	// - Google Chrome 81.0+
	// - Edge 81.0+
	// - Safari 13.1+
	// - Firefox 77+

	if (vendor === 'Google Inc.') {
		matched = appVersion.match(/\bChrome\/([0-9]+)/);
		return (matched && parseInt(matched[1], 10) >= 81);
	}

	if (vendor === 'Apple Computer, Inc.') {
		matched = appVersion.match(/\bVersion\/([0-9]+[.][0-9]+)/);
		return matched && parseFloat(matched[1]) >= 13.1;
	}

	if (/\bFirefox\b/.test(userAgent)) {
		matched = navigator.userAgent.match(/\bFirefox\/([0-9]+[.][0-9]+)\b/);
		return matched && parseFloat(matched[1]) >= 77.0;
	}

	return false;
};
