(function(global) {
	"use strict";
	
	if (!global.BS) {
		global.BS = {};
	}

	BS.Env = {
		iOS: /\biOS\b/.test(navigator.userAgent)
	,	legacy8: typeof window.getSelection !== "function"
	};

	BS.Event = {
		Tap: BS.Env.iOS ? "tap" : "click"
	};
	
})(window);
