/// BS.Dialog
/// モーダルダイアログを表示する
(function(global) {
	"use strict";

	const $ = jQuery;

	var BS = global.BS
	, CSS_PREFIX = "bs-dialog"
	, EVENT_CLASS = ".bs.dialog"
	, $body, $backdrop, visibleDialogs, maxId;

	visibleDialogs = [];
	maxId = 0;

	$(function initializeDOM() {
		$body = $(document.body);
		$backdrop = $("<div/>").addClass(CSS_PREFIX + "-backdrop fade").appendTo($body);
	});

	/// ================================================================
	/// ダイアログのボタン
	function DialogButton(props) {
		this.label = typeof props.label === "string" ? props.label : "";
		this.value = String(typeof props.value === "undefined" ? "" : props.value);
		this.style = typeof props.style === "string" ? props.style : "";
	}

	/// 標準的なボタンをセットしておく
	DialogButton.OK = [
		new DialogButton({ label: "OK", value: "OK", style: "btn btn-primary" })
	];

	DialogButton.OKCancel = [
		{ label: "OK", value: "OK", style: "btn btn-primary" }
	,	{ label: "キャンセル", value: "cancel", style: "btn btn-default" }
	];

	DialogButton.YesNo = [
		{ label: "はい", value: "yes", style: "btn btn-primary" }
	,	{ label: "いいえ", value: "no", style: "btn btn-default" }
	];

	/// ================================================================
	/// ダイアログ
	function Dialog() {
		this.initialize.apply(this, arguments);
	}

	/// @return dialog: Dialog?
	Dialog.getActiveDialog = function() {
		if (visibleDialogs.length) {
			return visibleDialogs[visibleDialogs.length - 1];
		}
		else {
			return null;
		}
	};

	Dialog.prototype = {

		/// @param title: String?
		/// @param subtitle: String | HTMLElement | $ | null
		/// @param buttons: [DialogButton]
		initialize: function(title, subtitle, buttons) {
			this.id = ++maxId;
			this.isShown = false;
			this.domReady = false;
			this.$dom = null;

			this.setTitle(title);
			this.setSubtitle(subtitle);
			this.setButtons(buttons);
		}

		/// @param title: String?
		, setTitle: function(title) {
			if (title === null || typeof title === "string") {
			}
			else {
				new TypeError("Title must be string or null");
			}
			if (this.title !== title) {
				this.title = title;
				this.domReady = false;
			}
		}

		/// @param subtitle: String | HTMLElement | $ | null
		, setSubtitle: function(subtitle) {
			if (subtitle === null ||
				typeof subtitle === "string" ||
				BS.DOM.isNode(subtitle) ||
				subtitle instanceof $) {
			}
			else {
				new TypeError("Subtitle must be string, HTMLElement, jQuery object or null");
			}
			if (this.subtitle !== subtitle) {
				this.subtitle = subtitle;
				this.domReady = false;
			}
		}

		/// @param buttons: [DialogButton]
		, setButtons: function(buttons) {
			this.buttons = normalizeButtons(buttons);
			this.domReady = false;
		}

		, getDOM: function() {
			return this.$dom[0];
		}

		, updateDOM: function() {
			var $modal, $dialog, $content, $actions, i, button;

			$modal = $("<div/>").addClass(CSS_PREFIX + " fade");
			$dialog = $("<div/>").addClass(CSS_PREFIX + "-dialog").appendTo($modal);
			$content = $("<div/>").addClass(CSS_PREFIX + "-content").appendTo($dialog);

			if (this.title !== null) {
				$content.append( createDialogDOM(this.title, "-title") );
			}

			if (this.subtitle !== null) {
				$content.append( createDialogDOM(this.subtitle, "-subtitle") );
			}

			if (this.buttons.length) {
				$actions = $("<div/>").addClass(CSS_PREFIX + "-actions").appendTo($content);
				i = 0;
				while ((button = this.buttons[i++])) {
					$("<button/>")
						.text(button.label)
						.attr("type", "button")
						.val(button.value)
						.addClass(CSS_PREFIX + "-action-btn " + button.style)
						.appendTo($actions);
				}
			}

			this.$dom = $modal;
			this.domReady = true;
		}

		, show: function() {
			if (this.isShown) {
				return;
			}

			if (!this.domReady) {
				this.updateDOM();
			}

			this.isShown = true;

			$body
				.addClass(CSS_PREFIX + "-open")
				.append(this.$dom);

			if (!Dialog.getActiveDialog()) {
				$backdrop
					.show()
					.addClass("in")
					.scrollTop(0);
			}

			this.$dom
				.addClass("in")
				.on("click" + EVENT_CLASS, "." + CSS_PREFIX + "-action-btn", $.proxy(this.buttonHandler, this));

			// 現在表示中のダイアログに追加
			visibleDialogs.push(this);
		}

		, hide: function() {
			if (! this.isShown) {
				return;
			}

			this.isShown = false;

			this.$dom
				.removeClass("in")
				.off("click" + EVENT_CLASS);

			if (!Dialog.getActiveDialog()) {
				$backdrop.removeClass("in");
			}

			setTimeout($.proxy(function() {
				this.$dom.remove();
				if (!Dialog.getActiveDialog()) {
					$backdrop.hide();
					$body.removeClass(CSS_PREFIX + "-open");
				}
			}, this), 200);

			// 現在表示中のダイアログから取り除く
			var i = 0, dialog;
			while ((dialog = visibleDialogs[i])) {
				if (this.id === dialog.id) {
					visibleDialogs.splice(i, 1);
					break;
				}
				i++;
			}
		}

		, buttonHandler: function(event) {
			var retval = $(event.target).val();
			this.hide();
			this.onhide(retval);
		}

		/// この関数をオーバーライドする
		, onhide: function(retval) {}
	};

	function normalizeButtons(buttons) {
		var i, p, aButtons;

		// 配列化 & コピー
		buttons = buttons != null ? buttons : [];
		buttons = [].slice.call(buttons);

		// DialogButton でない要素を取り除く
		aButtons = [];
		for (i = 0; i < buttons.length; i++) {
			aButtons.push(new DialogButton(buttons[i]));
		}

		return aButtons;
	}

	/// @param content: String | HTMLElement | $
	/// @param CSSClass: String
	/// @return $
	function createDialogDOM(content, CSSClass) {
		var $div = $("<div/>").addClass(CSS_PREFIX + CSSClass);

		if (typeof content === "string") {
			content.split("\n").forEach(function(line, index) {
				if (index !== 0) {
					$div.append(document.createElement("br"));
				}
				$div.append(document.createTextNode(line));
			});
		} else if (BS.DOM.isNode(content) || content instanceof $) {
			$div.append(content);
		}
		return $div;
	}

	function is(obj, proto) {
		for (var p in proto) {
			if (typeof proto[p] !== typeof obj[p]) {
				return false;
			}
		}
		return true;
	}

	/// ================================================================
	/// Expose

	BS.DialogButton = DialogButton;
	BS.Dialog = Dialog;

})(window);
