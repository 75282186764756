(function(ns) {
	"use strict";

	var Shrinker;

	Shrinker = function Shrinker() {
		this.init.apply(this, arguments);
	};

	Shrinker.prototype = {

		/// @param src: string
		/// @param options: {
		/// 	size: {width: number, height: number},
		/// 	orientation: number,
		/// 	maximumPixelCount: number
		/// }
		/// @param callback: function(image: {canvas: HTMLCanvasElement, width: number, height: number})
		init: function(src, options, callback) {
			var me = this;

			me.src = src;
			me.options = options;
			me.callback = callback;

			me.loadImage();
		}

		, loadImage: function() {
			var me = this, image;
			
			image = new Image;
			image.onload = function() {
				me.didLoadImage(image);
			};
			image.src = me.src;
		}

		/// @param image: HTMLImageElement
		, didLoadImage: function(image) {
			var me = this, srcImage, destImage, limit, width, height;

			srcImage = me.getSrcImage(image, me.options.orientation);
			
			limit = me.options.size;
			if (!limit || !limit.width || !limit.height ||
				(srcImage.width <= limit.width && srcImage.height <= limit.height)) {
				// サイズ指定なし、または指定サイズより小さい場合はもとの大きさとする
				width = srcImage.width;
				height = srcImage.height;
			}
			else {
				// 指定サイズに収まるように縮小
				width = limit.width;
				height = srcImage.height * limit.width / srcImage.width;
				if (height > limit.height) {
					height = limit.height;
					width = srcImage.width * limit.height / srcImage.height;
				}
			}

			destImage = ns.canvas.draw(null, srcImage.canvas, {
				dw: width,
				dh: height,
				maxPixels: ns.canvas.OptimalMaxPixels,
				allowTransparency: me.options.allowTransparency
			});

			srcImage = me.src = null; // メモリ解放
			if (typeof me.callback === "function") {
				me.callback(destImage);
			}
			destImage = null; // メモリ解放
		}

		/// @param image: HTMLImageElement
		/// @param orientation: number
		/// @return {canvas: HTMLCanvasElement, width: number, height: number}
		, getSrcImage: function(image, orientation) {
			var me = this;
			return ns.canvas.draw(null, image, {
				orientation: orientation,
				allowTransparency: me.options.allowTransparency
			});
		}

	};

	ns.Shrinker = Shrinker;

})(zzl.uploader);
