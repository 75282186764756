(function(ns) {
	"use strict";

	BS.DOMReady(function() {
		zzl.qs(".zzl-input-file").forEach(function(el) {
			new ns.FileUploader(el);
		});
	});
	
})(zzl.uploader);
