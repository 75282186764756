/*
	位置情報サービスを利用する
*/
(function(ns) {
	"use strict";

	var enabled = !!navigator.geolocation
	, wpid
	, callbacks = []
	;

	function observe(callback) {
		if (typeof callback !== "function") {
			throw new TypeError("callback must be a function");
		}
		if (!enabled) {
			return;
		}
		callbacks.push(callback);
		wpid = navigator.geolocation.watchPosition(didUpdate, didError, {enableHighAccuracy: true});
	}

	/// callback を指定しない場合はすべて解除する
	/// @param callback: Function?
	function stopObserve(callback) {
		if (callback) {
			for (var i = 0; i < callbacks.length; i++) {
				if (callback === callbacks[i]) {
					callbacks.splice(i, 1); // i 番目から 1 個、要素を取り除く
					break;
				}
			}
		}
		else {
			callbacks = [];
		}
		
		if (wpid && callbacks.length === 0) {
			navigator.geolocation.clearWatch(wpid);
			wpid = null;
		}
	}

	function didUpdate(loc) {
		for (var i = 0; i < callbacks.length; i++) {
			callbacks[i](loc);
		}
	}

	function didError(error) {
		for (var i = 0; i < callbacks.length; i++) {
			callbacks[i]({ error: error });
		}
	}

	// Expose
	ns.Loc = {
		observe: observe
		, stopObserve: stopObserve
		, PERMISSION_DENIED: 1
		, POSITION_UNAVAILABLE: 2
		, TIMEOUT: 3
	};
})(BS);
