(function(ns) {
	"use strict";

	const $ = jQuery;

	var FileUploader, errorDialog;

	if (!$) {
		throw new Error('ZZL requires jQuery');
	}
	if (!window.BS || !window.BS.DOM) {
		throw new Error('ZZL requires BS.DOM');
	}
	var EE = BS.DOM.create;

	errorDialog = new BS.Dialog("ファイルを追加できません", "", BS.DialogButton.OK);

	FileUploader = function FileUploader() {
		this.init.apply(this, arguments);
	};

	FileUploader.prototype = {

		init: function(dom) {
			var me = this, removeButton, parts, uid, name;

			// 最大ファイルサイズ
			me.maxFileSize = me.estimateMaxFileSize();

			// もとの <input>
			me.origInputE = dom.querySelector('input');

			// ファイル選択ボタン
			me.accept = me.origInputE.getAttribute("accept");
			me.fileInput = EE('input.zifi', {type: 'file', accept: me.accept});
			me.button = EE('button.zifb', {type: 'button'}, ['ファイルを選択']);
			me.inputWrap = EE('span.zifw', {}, [
				me.fileInput,
				me.button,
			]);

			// ファイル名表示
			me.infoText = EE('span.zift');

			// アップロードの進捗状況
			me.progress = EE('div.zifq');
			me.progressBar = EE('div.zifp', {}, [me.progress]);

			// 削除ボタン
			removeButton = EE('button.zifr.zzl-input-file-act', {
				type: 'button',
				'data-zzl-act': 'removeFile',
			});

			// wrapper
			me.wrapE = EE('div.zzl-input-file', {
				'data-zzl-input-file-index': me.index,
			}, [
				me.origInputE,
				me.inputWrap,
				me.infoText,
				me.progressBar,
				removeButton,
			]);
			BS.DOM.replace(dom, me.wrapE);

			// ファイル情報を設定
			me.uid = null;
			parts = me.origInputE.value.split("|");
			if (parts.length >= 1) {
				uid = parts[0];
				name = (parts[1] != null) ? parts[1] : 'noname';
			} else {
				uid = null;
				name = null;
			}
			me.setFileInfo(uid, name);

			// イベントを登録
			me.wrapE.addEventListener('click', function(event) {
				var target = BS.DOM.closest('.zzl-input-file-act', event.target);
				if (!target) return;
				var act = target.getAttribute("data-zzl-act");
				if (typeof me[act] === "function") {
					me[act]();
				}
			});
			me.wrapE.addEventListener('change', function(event) {
				var target = BS.DOM.closest('.zifi', event.target);
				if (!target) return;
				if (event && event.target && event.target.files && event.target.files[0]) {
					me.didSelectFile(event.target.files[0]);
				}
			});
		},

		estimateMaxFileSize: function() {
			if (!zzl.uploadMaxSize) {
				return Infinity;
			}
			var size = parseInt(zzl.uploadMaxSize, 10);
			if (isNaN(size)) {
				return Infinity;
			}
			return Math.floor((size - 26) * 3/4); // Data URI / Base64 エンコードのため
		},

		formatFileSize: function(size) {
			var units, keta, bai, yuukou, unitIndex;
			units = ["バイト", "kB", "MB", "GB"];

			if (typeof size !== "number") {
				return size;
			}
			if (size < 0) {
				return size + units[0];
			}

			keta = Math.floor(Math.log10(size));
			bai = keta % 3;
			yuukou = Math.round(size / Math.pow(10, keta), 2) * Math.pow(10, bai);
			if (yuukou >= 1000) {
				keta++;
				bai = 0;
				yuukou /= 1000;
			}
			unitIndex = Math.floor(keta / 3);
			while (unitIndex > units.length - 1) {
				yuukou *= 1000;
				unitIndex--;
			}
			return yuukou + units[unitIndex];
		},

		/// @param file: File - File API で提供されるファイル
		didSelectFile: function(file) {
			var me = this;

			try {
				me.validateFileType(file);
			} catch (e) {
				if (!errorDialog.isShown) {
					errorDialog.setSubtitle(e.message);
					errorDialog.show();
				}
				return;
			}

			me.uploadFile(file);

			// イベントを発火
			me.origInputE.dispatchEvent(new Event(ns.event.input));
			me.origInputE.dispatchEvent(new Event(ns.event.change));
		},

		validateFileType: function(file) {
			var me = this;
			if (!me.accept) return;

			var ext = (file.name.match(/[.][^.]+$/) || [''])[0];

			var matched = me.accept.split(/,/g).some(function(accept, i) {
				accept = accept.trim();
				if (accept === '*') return true;
				// 拡張子 (例: .csv)
				if (accept === ext) return true;
				// MIME
				var re = new RegExp("^" + accept.replace(/[*]/g, "[0-9a-z-]+") + "$", "i");
				return re.test(file.type);
			});
			if (!matched) throw new Error("指定された形式 (" + me.accept + ") のファイルを選択してください");
		},

		/// @param file: File - File API で提供されるファイル
		uploadFile: function(file) {
			var me = this, reader;

			if (file.size > me.maxFileSize) {
				if (!errorDialog.isShown) {
					errorDialog.setSubtitle("ファイルサイズがアップロード可能なサイズ (" + me.formatFileSize(me.maxFileSize) + ") を超えています");
					errorDialog.show();
				}
				return;
			}

			me.resetProgress();
			me.setProgressVisible(true);

			reader = new FileReader;
			reader.onload = function(event) {
				me.startUpload(file, event.target.result);
			};
			reader.readAsDataURL(file);
		},

		/// アップロードを開始する
		startUpload: function(file, content) {
			var me = this, uploadTo = zzl.uploadFileTo;

			$.ajax({
				url: uploadTo,
				type: "POST",
				data: {
					_VALIDPOST: zzl.env.post_token,
					content: content,
					size: file.size,
					name: file.name,
				},
				dataType: "json",

				// プログレスバーを表示するための設定
				xhr: function() {
					var xhr = $.ajaxSettings.xhr();
					if (xhr.upload && xhr.upload.addEventListener) {
						xhr.upload.addEventListener("progress", function(event) {
							me.setProgressValue(event.loaded / event.total);
						}, false);
					}
					return xhr;
				},

			}).done(function(data) {
				me.onUploadComplete(data);

			}).fail(function(data) {
				me.onUploadFail((data.responseJSON && data.responseJSON.message) || "");
			});
		},

		/// アップロード完了
		onUploadComplete: function(data) {
			var me = this;
			me.resetInput();
			me.setFileInfo(data.uid, data.name);
			me.setProgressVisible(false);

			// イベントを発火
			me.origInputE.dispatchEvent(new Event(ns.event.uploadComplete));
		},

		/// アップロード失敗
		onUploadFail: function(error) {
			var me = this;
			if (!errorDialog.isShown) {
				errorDialog.setSubtitle(localizeErrorMessage(error));
				errorDialog.show();
			}
			me.resetInput();
			me.setProgressVisible(false);

			// イベントを発火
			me.origInputE.dispatchEvent(new Event(ns.event.uploadFail));
		},

		/// <input type="file"> をリセット
		resetInput: function() {
			this.fileInput.value = '';
		},

		removeFile: function() {
			var me = this;
			me.setFileInfo(null, null);
		},

		setFileInfo: function(uid, name) {
			var me = this;
			if (uid && name) {
				me.uid = uid;
				if (zzl.uploadFilePrefix) {
					var infoText = EE('span.zift', {}, [
						EE('span.ziftn', {}, [name, ' ']),
						EE('a.zifsf.btn.btn-xs.btn-default', {
							href: zzl.uploadFilePrefix + uid,
							target: '_blank',
						}, ['ファイルを確認']),
					]);
					BS.DOM.replace(me.infoText, infoText);
					me.infoText = infoText;
				} else {
					me.infoText.textContent = name;
				}
				me.origInputE.value = uid + "|" + name;
				me.wrapE.classList.add("zifs-selected");
			} else {
				me.uid = null;
				me.infoText.textContent = "選択されていません";
				me.origInputE.value = '';
				me.wrapE.classList.remove("zifs-selected");
			}
		},

		/// @param visible: Bool
		setProgressVisible: function(visible) {
			this.wrapE.classList[visible ? "add" : "remove"]("zifs-progress");
		},

		/// @param value: Int - 0-1 の値
		setProgressValue: function(value) {
			value = Math.round(parseFloat(value) * 100);
			if (value < 0) {
				value = 0;
			} else if (value > 100) {
				value = 100;
			}
			BS.DOM.setStyles(this.progress, { width: value + "%" });
		},

		resetProgress: function() {
			this.progress.removeAttribute("style");
		},
	};

	function localizeErrorMessage(message) {
		if (/\binternal\b|\bI\/O\b/i.test(message)) {
			return "アップロードに失敗しました";
		} else if (/\binvalid request\b/i.test(message)) {
			return "アップロードに失敗しました。ファイルサイズが大きすぎないかご確認ください";
		} else {
			return message;
		}
	}

	ns.FileUploader = FileUploader;

})(zzl.uploader);
