/// @require jQuery
(function(global) {
	"use strict";

	const $ = jQuery;

	var formElementSelector = "input, select, textarea"
	, proxyFormClass = "bs-form-proxy"
	;

	/// フォーム内の値を連想配列として返す
	///
	/// @param container: DOMElement -- フォーム部品を含む親要素
	/// @return values: [String : String] -- 値
	function getValues(container) {
		var values = {};
		$(container).filter(formElementSelector).each(iter);
		$(container).find(formElementSelector).each(iter);

		return values;

		function iter() {
			var $el = $(this), name = $el.attr("name"), val = $el.val();

			// データに含めない項目を除外する
			// - name 属性が設定されていない
			// - disabled 属性が設定されている
			// - ラジオボタン、チェックボックスの場合で、チェックされていない
			if (name == null ||
				$el.attr("disabled") ||
				(this.tagName.toLowerCase() === "input" &&
					/^checkbox$|^radio$/.test($el.attr("type")) &&
					!$el.prop("checked"))) {
				return;
			}

			// すでにキーが存在する場合は配列として追加する
			if (name in values) {
				if (values[name] instanceof Array) {
					values[name].push(val);
				}
				else {
					values[name] = [values[name], val];
				}
			}
			else {
				values[name] = val;
			}
		}
	}

	/// 連想配列をクエリ文字列にする
	///
	/// @param obj: AnyObject
	/// @param (optional) prefix: String
	/// @return queryString: String
	function getQueryString(obj, prefix) {
		var str = [], p, k, v;
		for (p in obj) {
			if (isFunction(obj[p])) {
				continue;
			}
			k = prefix ? prefix + "[" + p + "]" : p;
			v = obj[p];
			str.push(isObject(v) ? getQueryString(v, k) : (k + "=" + encodeURIComponent(v)));
		}
		return str.join("&");
	}

	/// フォームを指定した action へ送信する
	/// action 属性を直接変更して送信すると、ブラウザによってフォームの値が保持されない場合があるため、この関数を使用する
	///
	/// @param action: String
	/// @param form: HTMLFormElement
	/// @return false: Boolean - on... 属性で使用するため、常に false を返す
	function submitTo(action, form) {
		var $form = $(form);
		navigate($form.attr("method"), action, $form.serialize(), $form.attr("target"));
		return false;
	}

	/// HTML 上に存在しないフォームを送信する
	/// 動作確認 (2014-11-12): Explorer 11 (also 8-10 mode), Safari 7.1, Firefox 33.1, Chrome 38, Opera 25
	///
	/// @param method: String ["GET" | "POST"]
	/// @param action: String
	/// @param data: [String : AnyObject] | String
	/// @param target: String?
	/// @return false: Boolean
	function navigate(method, action, data, target) {
		var $proxy;

		method = (method === "post" || method === "POST") ? "POST" : "GET";

		$("." + proxyFormClass).remove();

		$proxy = $("<form>").addClass(proxyFormClass)
			.attr({ method: method, action: action, target: target || "" })
			.css({ position: "absolute"/*, width: 0, height: 0, overflow: "hidden", visibility: "hidden"*/ });

		createFormElementFromObject($proxy, data);

		$(document.body).append($proxy);
		$proxy.submit().remove();

		return false;
	}

	/// @param $form: $
	/// @param data: [String : AnyObject] | String
	function createFormElementFromObject($form, data) {
		var param, pairs, i, pair, name, value;

		switch (typeof data) {
		case "object":
			param = $.param(data);
			break;
		case "string":
			param = data; // query string 形式
			break;
		default:
			return;
		}

		pairs = param.replace(/\+/g, " ").split("&");
		for (i = 0; i < pairs.length; i++) {
			if (pairs[i] === "") continue;
			pair = pairs[i].split("=");
			name = decodeURIComponent(pair[0]);
			value = decodeURIComponent(pair[1]);
			$("<input>").attr({ type: "hidden", name: name, value: value }).appendTo($form);
		}
	}

	// ================================================================
	// Utilities

	function isFunction(obj) {
		return typeof obj === "function" && !(obj instanceof RegExp);
	}

	function isObject(obj) {
		return typeof obj === "object" && obj !== null;
	}

	// ================================================================
	// Expose

	global.BS.Form = {
		values: getValues
		, queryString: getQueryString
		, formElementSelector: formElementSelector
		, submitTo: submitTo
		, navigate: navigate
	};

})(window);
