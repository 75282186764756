(function(ns) {
	"use strict";

	var expose;
	if (!ns.func) {
		ns.func = {};
	}
	expose = ns.func;

	/// this オブジェクトを束縛した関数を返す
	/// @param func: function
	/// @param context: object
	expose.proxy = function(func, context) {
		var args = [].slice.call(arguments, 2);
		
		if (typeof func !== "function") {
			throw new TypeError("func must be a function");
		}
		
		return function() {
			return func.apply(context || this, args.concat([].slice.call(arguments)));
		};
	};

	/// 遅延実行する関数を返す
	/// @param func: function
	/// @param delay: number
	/// @param context: object?
	expose.delayed = function(func, delay, context) {
		var timer = null;
		
		if (typeof func !== "function") {
			throw new TypeError("func must be a function");
		}
		
		return function() {
			if (timer) {
				clearTimeout(timer);
			}
			timer = setTimeout(function() {
				func.apply(context || this);
			}, delay);
		};
	};

})(BS);
