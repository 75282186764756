(function(ns) {
	"use strict";

	var DOM = BS.DOM,
	Slideshow,
	useAnima = BS.Env.CSSAnimation,
	defaults = {
		interval: 5000,
		firstDelay: 1000,
		hoverToStop: false,
		itemClass: "slide",
		activeClass: "active",
		fadeInClass: "in",
		fadeInReverseClass: "in-rev",
		navClass: "slide-nav",
		navItemClass: "slide-nav-item",
		showTitle: false,
		titleClass: "slide-title",
		prevButtonClass: "slide-prev",
		nextButtonClass: "slide-next",
	};

	Slideshow = function Slideshow() {
		this.init.apply(this, arguments);
	};

	Slideshow.prototype = {

		init: function(E, options) {
			var me = this, images, i;

			// オプション読み込み
			me.opt = Object.assign({}, defaults, options || {});

			// DOM 要素作成
			me.listE = E;
			DOM.empty(E);

			me.activeE = me.createSlide();
			me.activeE.classList.add(me.opt.activeClass);
			E.appendChild(me.activeE);

			me.altE = me.createSlide();
			E.appendChild(me.altE);

			me.navE = DOM.create("ol." + me.opt.navClass);
			E.appendChild(me.navE);

			// データ読み込み
			try {
				images = JSON.parse(me.listE.getAttribute("data-images"));
			} catch (e) {
				throw new Error("Attribute \"data-images\" is not a valid JSON data");
			}
			if (!Array.isArray(images)) {
				throw new Error("Content of \"data-images\" must be an array");
			}
			me.images = images;
			me.length = images.length;
			me.index = 0;
			if (me.length < 1) {
				return;
			}

			// プリロード
			BS.Preloader.register(me.images);

			// 最初の画像を設定
			me.setImage(me.activeE, me.images[0]);
			me.setImage(me.altE, me.images[0]);
			if (me.length >= 2) {
				me.timer = setTimeout(function() {
					me.next();
				}, me.opt.interval + me.opt.firstDelay);
			}

			// ナビゲーションを設定
			me.navItemEs = [];
			for (i = 0; i < me.length; i++) {
				var liE = DOM.create("li." + me.opt.navItemClass, {
					"data-index": i,
				}, [String(i + 1)]);
				me.navItemEs.push(liE);
				me.navE.appendChild(liE);
			}
			me.navItemEs[0].classList.add(me.opt.activeClass);

			me.navE.addEventListener("click", BS.func.proxy(me.didTapNav, me));

			// 前後のナビゲーションを設定
			me.prevE = DOM.create("div." + me.opt.prevButtonClass);
			me.listE.appendChild(me.prevE);
			me.prevE.addEventListener("click", BS.func.proxy(me.prev, me));

			me.nextE = DOM.create("div." + me.opt.nextButtonClass);
			me.listE.appendChild(me.nextE);
			me.nextE.addEventListener("click", BS.func.proxy(me.next, me));

			// マウスオーバーで自動切り替えを止める
			if (me.opt.hoverToStop) {
				me.listE.addEventListener("mouseenter", BS.func.proxy(me.setHover, me, true));
				me.listE.addEventListener("mouseleave", BS.func.proxy(me.setHover, me, false));
			}
		},

		createSlide: function() {
			return DOM.create("div." + this.opt.itemClass);
		},

		setImage: function(E, image) {
			var me = this, linkE, titleE;
			
			DOM.empty(E);
			E.style.backgroundImage = "url('" + image.src + "')";
			
			if (image.link_url) {
				linkE = DOM.create("a", {href: image.link_url});
				if (image.link_target) {
					linkE.setAttribute("target", image.link_target);
				}
				E.appendChild(linkE);
			}
	
			if (me.opt.showTitle && typeof image.title === "string") {
				titleE = DOM.create("span." + me.opt.titleClass, {}, image.title);
				E.appendChild(titleE);
			}
		},

		/// nextIndex のスライドへ移動する
		go: function(nextIndex, reverse) {
			var me = this, tmpE;

			if (nextIndex < 0 || nextIndex >= me.length) {
				return;
			}

			// 次の画像を用意
			me.setImage(me.altE, me.images[nextIndex]);

			// 切り替え効果を適用
			me.activeE.classList.remove(me.opt.activeClass, me.opt.fadeInClass, me.opt.fadeInReverseClass);
			me.altE.classList.add(me.opt.activeClass, me.opt.fadeInClass);
			if (reverse) {
				me.altE.classList.add(me.opt.fadeInReverseClass);
			}

			// ナビゲーションを設定
			me.navItemEs[me.index].classList.remove(me.opt.activeClass);
			me.navItemEs[nextIndex].classList.add(me.opt.activeClass);

			// 内部変数を次に移動
			me.index = nextIndex;
			tmpE = me.activeE;
			me.activeE = me.altE;
			me.altE = tmpE;

			// 次へ
			me.setNextTimer(me.opt.interval);
		},

		/// 次のスライドへ移動するタイマーを設定する
		setNextTimer: function(interval) {
			var me = this;

			clearTimeout(me.timer);

			if (interval < Infinity) {
				me.timer = setTimeout(function() {
					me.next();
				}, interval);
			}
		},

		/// ナビゲーションボタンをタップした
		didTapNav: function(event) {
			var me = this, targetE, index;
			event.preventDefault();

			targetE = DOM.closest("." + me.opt.navItemClass, event.target);
			if (!targetE) {
				return;
			}
			index = parseInt(targetE.getAttribute("data-index"), 10);
			me.go(index);
		},

		/// 前へ
		prev: function() {
			var me = this, index;
			index = me.index - 1;
			if (index < 0) {
				index = me.images.length - 1;
			}
			if (index !== me.index) {
				me.go(index, true);
			}
		},

		/// 次のスライドへ移動する
		next: function() {
			var me = this, nextIndex;

			nextIndex = (me.index + 1) % me.length;
			if (me.index !== nextIndex) {
				me.go(nextIndex);
			}
		},

		setHover: function(hover) {
			var me = this;
			
			if (hover) {
				clearTimeout(me.timer);
			}
			else {
				// 通常の半分の時間で復帰
				me.setNextTimer(me.opt.interval * 0.5);
			}
		},
	};

	ns.Slideshow = Slideshow;
	
})(BS);
