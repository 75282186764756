/*
	var dialog = CropDialog;
	dialog.didComplete = function(cropInfo) {
		// もとの画像上の位置と大きさを表す
		cropInfo.x;
		cropInfo.y;
		cropInfo.width;
		cropInfo.height;
		cropInfo.orientation; // Exif に準ずる
	};
	dialog.didCancel = function() {
	};
	dialog.show();

	■ DOM 構造

	(BS.Dialog DOM)
		.zii-cropper(.ready)
			.zii-cropper-frame 枠
				.zii-cropper-outer
					.zii-cropper-inner
			.zii-cropper-image プレビュー画像
			.zii-cropper-cover 領域外を暗くする（上）
			.zii-cropper-cover 領域外を暗くする（下）
			.zii-cropper-cover 領域外を暗くする（左）
			.zii-cropper-cover 領域外を暗くする（右）
		.zii-cropper-zoom 拡大操作
			.zii-cropper-zi
			.zii-cropper-zs
*/
(function(ns) {
	"use strict";

	const $ = jQuery;

	var CropDialog
	, round = Math.round
	, windowE
	// イベント名を間違えた場合に検出できるようにする定数
	, eventNamespace = ".CropDialog"
	, EV = {
		resize: "resize"
		, change: "change"
		, input: "input"
		, mousedown: "mousedown"
		, mousemove: "mousemove"
		, mouseup: "mouseup"
		, touchstart: "touchstart"
		, touchmove: "touchmove"
		, touchend: "touchend"
	}
	, frameSizeRatio = 0.7
	, snapValue = 0.05
	;

	CropDialog = function CropDialog() {
		this.init.apply(this, arguments);
	};

	CropDialog.prototype = {

		/// @param image: {canvas: HTMLCanvasElement, width: number, height: number}
		/// @param targetSize: {x: number, y: number} - 切り抜き後のサイズ
		/// @param orientation: number - Exif に準ずる
		init: function(image, targetSize, orientation) {
			var me = this;

			me.targetSize = targetSize;
			me.aspectRatio = targetSize.x / targetSize.y;

			me.image = image;
			me.imageAspectRatio = image.width / image.height;
			me.orientation = orientation;

			// 切り抜きの中心点。画面上の倍率でのピクセル数で表す
			me.pos = {x: 0, y: 0};

			// 画像の倍率。切り抜き枠をぴったり覆っている大きさを 1 とする
			me.zoom = 1;

			// 画像の最大倍率
			me.maxZoom = 4;

			// 画像の最小倍率
			me.minZoom = (function() {
				var zoom = (targetSize.x * image.height) / (targetSize.y * image.width);
				if (me.imageAspectRatio < me.aspectRatio) {
					zoom = 1/zoom;
				}
				return Math.round(zoom * 100) / 100;
			})();

			me.windowE = $(window);
			me.dialog = null;
			me.dialogE = null;
			me.containerE = null;
			me.frameE = null;
			me.coverEs = null;
			me.imageE = null;
			me.zoomE = null;

			// 画像の位置を操作するための変数
			me.prevTouchPoint = null;
			me.isPinch = false;
			me.frameSize = null;
			me.imageSize = null;
			me.moveBounds = null;

			me.createDOM();
		}

		/// メモリ解放
		, destroy: function() {
			var me = this;
			me.image = null;
		}

		, show: function() {
			var me = this;
			me.dialog.show();
			me.dialogE = $(me.dialog.getDOM()).addClass("zii-cropper-dialog");
			me.setDialogEvents();

			// サイズを設定
			me.onResize();

			me.containerE.addClass("ready");
		}

		, createDOM: function() {
			var me = this, dialogDOM;

			dialogDOM = $(BS.Template.createFragment(
				'<div>' +
					'<div class="zii-cropper">' +
						'<div class="zii-cropper-frame">' +
							'<div class="zii-cropper-outer"><div class="zii-cropper-inner"></div></div>' +
						'</div>' +
						'<div class="zii-cropper-image"></div>' +
						'<div class="zii-cropper-cover t"></div>' +
						'<div class="zii-cropper-cover b"></div>' +
						'<div class="zii-cropper-cover l"></div>' +
						'<div class="zii-cropper-cover r"></div>' +
					'</div>' +
					'<div class="zii-cropper-zoom">' +
						'<span class="zii-cropper-zi"></span>' +
						'<input class="zii-cropper-zs" min="' + me.minZoom + '" max="' + me.maxZoom + '" step="0.01" value="' + me.zoom + '" type="range" data-error="">' +
					'</div>' +
				'</div>').firstChild);
			me.containerE = dialogDOM.find(".zii-cropper");
			me.frameE = dialogDOM.find(".zii-cropper-frame");
			me.coverEs = {
				v: dialogDOM.find(".zii-cropper-cover").filter(".t, .b")
			,	h: dialogDOM.find(".zii-cropper-cover").filter(".l, .r")
			};
			me.imageE = dialogDOM.find(".zii-cropper-image");
			me.zoomE = dialogDOM.find(".zii-cropper-zs");

			me.imageE.append(me.image.canvas);

			me.dialog = new BS.Dialog(null, dialogDOM, BS.DialogButton.OKCancel);

			me.dialog.onhide = function(retval) {
				switch (retval) {
				case "OK":
					me.willComplete();
					break;
				default:
					me.willCancel();
					break;
				}
			};
		}

		, setDialogEvents: function() {
			var me = this;

			me.windowE.on(EV.resize + eventNamespace, BS.func.delayed(me.onResize, 100, me));
			me.containerE.on(EV.touchstart + eventNamespace, BS.func.proxy(me.onTouchstart, me))
				.on(EV.touchmove + eventNamespace, BS.func.proxy(me.onTouchmove, me))
				.on(EV.touchend + eventNamespace, BS.func.proxy(me.onTouchend, me))
				.on(EV.mousedown + eventNamespace, BS.func.proxy(me.onMousedown, me))
				.on(EV.mousemove + eventNamespace, BS.func.proxy(me.onMousemove, me))
				.on(EV.mouseup + eventNamespace, BS.func.proxy(me.onMouseup, me));
			me.zoomE.on(EV.change + eventNamespace, BS.func.proxy(me.onZoomChange, me))
				.on(EV.input + eventNamespace, BS.func.proxy(me.onZoomChange, me));
		}

		, unsetDialogEvents: function() {
			var me = this;

			me.windowE.off(eventNamespace);
			me.containerE.off(eventNamespace);
			me.zoomE.off(eventNamespace);
		}

		, onResize: function(event) {
			var me = this, containerSize, frameSize, frameOuterSize, imageSize, paddingV, paddingH;

			// Cropper の領域に合わせて切り取り枠の大きさを設定する
			containerSize = {
				x: me.containerE.width()
			,	y: me.containerE.height()
			};
			frameSize = getContainSize({x: containerSize.x * 0.7, y: containerSize.y * 0.7}, me.aspectRatio);
			frameOuterSize = {x: frameSize.x + 2, y: frameSize.y + 2}; // 枠が上下左右 2 px あるため
			setDivSize(me.frameE, frameOuterSize);
			setDivCenter(me.frameE, containerSize, frameOuterSize);

			// プレビュー画像の大きさを設定する
			imageSize = getCoverSize(frameSize, me.imageAspectRatio);
			setDivSize(me.imageE, imageSize);
			setDivCenter(me.imageE, containerSize, imageSize);

			// 周りの暗い部分の大きさを設定する
			paddingV = Math.round((containerSize.y - frameSize.y) / 2);
			paddingH = Math.round((containerSize.x - frameSize.x) / 2);
			me.coverEs.v.css({height: paddingV + "px"});
			me.coverEs.h.css({top: paddingV + "px", bottom: paddingV + "px", width: paddingH + "px"});

			// 移動・拡大縮小操作用の変数
			me.frameSize = frameSize;
			me.imageSize = imageSize;
		}

		, onTouchstart: function(event) {
			var me = this, touchPoints;
			event.preventDefault();

			touchPoints = getTouchPoints(event);
			if (touchPoints.length === 1) { // 最初のタッチ
				me.prevTouchPoint = touchPoints[0];
			}
			else if (touchPoints.length >= 2) {
				me.isPinch = true;
			}
			me.updateMoveBounds();
		}

		, onTouchmove: function(event) {
			var me = this, touchPoints;
			event.preventDefault();

			if (!me.prevTouchPoint) {
				return;
			}

			touchPoints = getTouchPoints(event);
			me.moveImage(touchPoints[0]);

			if (me.isPinch) {
				// TODO: 拡大縮小
			}
		}

		, onTouchend: function(event) {
			var me = this, touchPoints;

			touchPoints = getTouchPoints(event);
			me.moveImage(touchPoints[0]);

			if (touchPoints.length < 2) {
				me.isPinch = false;
			}
			else if (touchPoints.length === 0) {
				me.prevTouchPoint = null;
			}
		}

		, onMousedown: function(event) {
			var me = this, touchPoints;
			event.preventDefault();

			touchPoints = getMousePoints(event);
			me.prevTouchPoint = touchPoints[0];
			me.updateMoveBounds();
		}

		, onMousemove: function(event) {
			var me = this, touchPoints;
			event.preventDefault();

			if (!me.prevTouchPoint) {
				return;
			}

			touchPoints = getMousePoints(event);
			me.moveImage(touchPoints[0]);
		}

		, onMouseup: function(event) {
			var me = this, touchPoints;
			event.preventDefault();

			if (!me.prevTouchPoint) {
				return;
			}

			touchPoints = getMousePoints(event);
			me.moveImage(touchPoints[0]);
			me.prevTouchPoint = null;
		}

		, onZoomChange: function(event) {
			var me = this, zoom;

			zoom = me.zoomE.val();
			if (1 - snapValue <= zoom && zoom <= 1 + snapValue) {
				zoom = 1;
			}
			me.setZoom(zoom);
		}

		, moveImage: function(aTouchPoint) {
			var me = this, x, y;

			// 新しい位置を計算
			x = me.pos.x + (aTouchPoint.x - me.prevTouchPoint.x);
			y = me.pos.y + (aTouchPoint.y - me.prevTouchPoint.y);

			// 移動可能な範囲内に制限
			me.pos = {
				x: Math.max(-me.moveBounds.x, Math.min(me.moveBounds.x, x)),
				y: Math.max(-me.moveBounds.y, Math.min(me.moveBounds.y, y))
			};
			me.prevTouchPoint = aTouchPoint;

			me.setImagePosition(me.pos, me.zoom);
		}

		, setZoom: function(aZoom) {
			var me = this, x, y;

			// 新しい位置を計算
			x = me.pos.x * aZoom / me.zoom;
			y = me.pos.y * aZoom / me.zoom;

			// 移動可能な範囲内に制限
			me.zoom = aZoom;
			me.updateMoveBounds();
			me.pos = {
				x: Math.max(-me.moveBounds.x, Math.min(me.moveBounds.x, x)),
				y: Math.max(-me.moveBounds.y, Math.min(me.moveBounds.y, y))
			};

			me.setImagePosition(me.pos, aZoom);
		}

		/// 移動可能な領域を設定
		, updateMoveBounds: function() {
			var me = this, zoom;

			zoom = me.zoom;
			me.moveBounds = {
				x: Math.abs((zoom * me.imageSize.x - me.frameSize.x) / 2),
				y: Math.abs((zoom * me.imageSize.y - me.frameSize.y) / 2)
			};
		}

		, setImagePosition: function(pos, zoom) {
			var me = this, tstr;

			tstr = "translate(" + Math.round(pos.x) + "px, " + Math.round(pos.y) + "px) scale(" + zoom + ")";
			me.imageE.css({
				transform: tstr
				, webkitTransform: tstr
			});
			me.zoomE.val(zoom);
		}

		, willComplete: function() {
			var me = this, scale, cropInfo;
			me.unsetDialogEvents(me.dialog);

			scale = me.image.width / (me.zoom * me.imageSize.x);
			cropInfo = {
				sx: (-me.pos.x + (me.zoom * me.imageSize.x - me.frameSize.x) / 2) * scale,
				sy: (-me.pos.y + (me.zoom * me.imageSize.y - me.frameSize.y) / 2) * scale,
				sw: me.frameSize.x * scale,
				sh: me.frameSize.y * scale,
				dw: me.targetSize.x,
				dh: me.targetSize.y
			};

			me.didComplete(cropInfo);
			me.destroy();
		}

		, willCancel: function() {
			var me = this;
			me.unsetDialogEvents(me.dialog);
			me.didCancel();
			me.destroy();
		}

		/// 切り取りが実行されたときに呼ばれるハンドラ
		/// @param cropInfo: {sx: number, sy: number, sw: number, sh: number, dw: number, dh: number}
		, didComplete: function(/* cropInfo */) {}

		/// 切り取りがキャンセルされたときに呼ばれるハンドラ
		, didCancel: function() {}
	};

	/// content を container に収まる大きさに拡大縮小したときの、幅と高さを返す
	/// @param container: {x: number, y: number}
	/// @param content: {x: number, y: number} || number - 大きさまたはアスペクト比
	/// @return size: {x: number, y: number}
	function getContainSize(container, content) {
		var aspectRatio, width, height;

		aspectRatio = (typeof content === "number") ? content : content.x / content.y;
		if (container.x / container.y > aspectRatio) {
			height = container.y;
			width = height * aspectRatio;
		}
		else {
			width = container.x;
			height = width / aspectRatio;
		}
		return { x: width, y: height };
	}

	/// container を覆う大きさに content を拡大縮小したときの、幅と高さを返す
	/// @param container: {x: number, y: number}
	/// @param content: {x: number, y: number} || number - 大きさまたはアスペクト比
	/// @return size: {x: number, y: number}
	function getCoverSize(container, content) {
		var aspectRatio, width, height;

		aspectRatio = (typeof content === "number") ? content : content.x / content.y;
		if (container.x / container.y > aspectRatio) {
			width = container.x;
			height = width / aspectRatio;
		}
		else {
			height = container.y;
			width = height * aspectRatio;
		}
		return { x: width, y: height };
	}

	function setDivSize(el, size) {
		el.css({
			width: round(size.x) + "px"
			, height: round(size.y) + "px"
		});
	}

	function setDivCenter(el, parentSize, size) {
		el.css({
			left: round(parentSize.x / 2) + "px"
			, top: round(parentSize.y / 2) + "px"
			, marginLeft: -round(size.x / 2) + "px"
			, marginTop: -round(size.y / 2) + "px"
		});
	}

	/// タッチイベントのタッチ位置を配列で返す
	/// @param event: TouchEvent
	/// @return points: [{x: number, y: number}]
	function getTouchPoints(event) {
		var points, touches, i, touch;
		points = [];
		touches = event.touches || event.originalEvent.touches
		for (i = 0; (touch = touches[i]); i++) {
			points.push({x: touch.pageX, y: touch.pageY});
		}
		return points;
	}

	/// マウスイベントのポインタ位置を配列で返す
	/// @param event: MouseEvent
	/// @return points: [{x: number, y: number}]
	function getMousePoints(event) {
		return [{x: event.pageX, y: event.pageY}];
	}

	ns.CropDialog = CropDialog;

})(zzl.uploader);
