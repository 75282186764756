(function(ns) {
	"use strict";
	
	var Normal = 1;

	function Bin(content) {
		if (typeof content !== "object" || !ArrayBuffer || content.constructor !== ArrayBuffer) {
			throw new TypeError("file content must be ArrayBuffer");
		}
		this.buf = content;
		this._ = new Uint8Array(content);
		this.length = this._.length;
	}
	Bin.prototype = {
		// char at
		c: function(pos) {
			return this._[pos];
		}
		// short at
		, s: function(pos, bigEndian) {
			var a = this._[pos]
			, b = this._[pos + 1]
			;
			return bigEndian ?
			(256*a + b) :
			(a + 256*b);
		}
		// long at
		, l: function(pos, bigEndian) {
			var a = this._[pos]
			, b = this._[pos + 1]
			, c = this._[pos + 2]
			, d = this._[pos + 3]
			;
			return bigEndian ?
			(256*(256*(256*a + b) + c) + d) :
			(a + 256*(b + 256*(c + 256*d)));
		}
		// string
		, substr: function(offset, length) {
			var s = ""
			, limit = length ? offset + length : this.length
			;
			if (limit > this.length) {
				limit = this.length;
			}
			for (var i = offset; i < limit; i++) {
				s += String.fromCharCode(this._[i]);
			}
			return s;
		}
	};

	/// Exif の画像回転情報を得る
	/// @param content: ArrayBuffer - Content of image file
	/// @return orientation: number
	function getOrientation(content) {
		try {
			var bin = new Bin(content);
			if (bin.c(0) !== 0xFF || bin.c(1) !== 0xD8) {
				return 0; // not a valid jpeg
			}

			var off = 2;
			while (off < bin.length) {
				if (bin.c(off) !== 0xFF) {
					return 0; // not a valid marker
				}
				var len = bin.s(off + 2, true);
				if (bin.c(off + 1) === 0xE1) {
					// found Exif marker
					return readExifData(bin, off + 4, len - 2);
				} else {
					off += 2 + len;
				}
			}
			return Normal;
		}
		catch (e) {
			return 0;
		}
	}

	function readExifData(bin, off) {
		if (bin.substr(off, 4) !== "Exif") {
			return 0; // not a valid Exif data
		}

		// detect endianness
		var bigEndian
		, tiffOffset = off + 6
		;
		switch (bin.s(tiffOffset)) {
			case 0x4949: bigEndian = false; break;
			case 0x4D4D: bigEndian = true; break;
			default: return 0;
		}

		if (bin.s(tiffOffset + 2, bigEndian) !== 0x002A) {
			return 0; // not valid
		}
		if (bin.l(tiffOffset + 4, bigEndian) !== 0x00000008) {
			return 0; // not valid
		}

		return readTag(bin, tiffOffset + 8, bigEndian);
	}

	function readTag(bin, off, bigEndian) {
		var entries = bin.s(off, bigEndian);
		off += 2;
		for (var i = 0; i < entries; i++) {
			var index = bin.s(off, bigEndian);
			if (index === 0x0112) { // Orientation tag
				return bin.s(off + 8, bigEndian);
			}
			off += 12;
		}
		return Normal;
	}
	
	ns.exif = {
		getOrientation: getOrientation
	};
})(zzl);
