;[].forEach.call(document.querySelectorAll('.form-group-collapse'), function(group) {
	const $ = jQuery;
	var collapsed = group.classList.contains('collapsed')
	var toggler = group.querySelector('.form-group-toggle-collapse')
	if (!toggler) return
	var controls = group.querySelector('.form-controls')
	if (!controls) return
	if (collapsed) $(controls).hide()
	toggler.addEventListener('click', function(event) {
		event.preventDefault()
		$(controls)[collapsed ? 'slideDown' : 'slideUp'](150)
		group.classList[collapsed ? 'remove' : 'add']('collapsed')
		collapsed = !collapsed
	})
})
