/// BS.Template
/// Mustache 風の、より簡素なテンプレート
(function(global) {
	"use strict";

	var BS = global.BS;

	/// ================================================================
	/// テンプレートにエラーがある場合はこの例外オブジェクトを投げる
	function TemplateError(message) {
		this.name = "TemplateError";
		this.message = message || null;
	}

	TemplateError.prototype = new Error;
	TemplateError.prototype.constructor = TemplateError;

	/// ================================================================
	/// トークン
	function Token(type, str) {
		var me = this;
		
		me.type = type;
		me.mark = null;
		me.str = str;
		me.children = null;

		if (type === Token.Tag) {
			str = str.replace(/^\s*|\s*$/g, "");
			if (/^[!&#^\/]/.test(str)) {
				me.mark = str[0];
				me.str = str.substring(1).replace(/^\s+/, "");
			} else {
				me.str = str;
			}
		}
	}

	Token.Text = "text";
	Token.Tag = "tag";

	/// ================================================================
	/// テンプレート

	/// @param str: string - テンプレート本文
	/// @param filters: [string : function] - フィルタ関数のリスト
	function Template(str, filters) {
		var me = this;
		
		if (typeof str !== "string") {
			throw new TypeError("Templete must be a string");
		}

		// 設定
		me.delimiterBegin = "{{";
		me.delimiterEnd = "}}";
		me.escape = BS.Entities.encode;
		me.filters = filters || {};

		// テンプレート本文
		me.str = str;

		// 解析済のテンプレート
		me.tokens = null; // [Token]?
	}

	Template.prototype = {

		/// @param AnyObject
		/// @return String
		render: function(data) {
			var me = this, out;
			
			if (!me.tokens) {
				me.compile();
			}

			out = [];
			me.render_sub(me.tokens, [data], out);
			return out.join("");
		}

		, render_sub: function(tokens, scopes, out) {
			var me = this, i, j, token, val;
			
			for (i = 0; i < tokens.length; i++) {
				token = tokens[i];
				if (token.type === Token.Text) {
					out.push(token.str);
				}
				else {
					val = me.resolve(token.str, scopes);
					if (token.mark === null) {
						out.push(me.escape( val == null ? "" : val )); // エスケープする
					}
					else if (token.mark === "&") {
						out.push( val == null ? "" : val ); // エスケープしない
					}
					else if (token.mark === "!") {
						// コメントなので何も出力しない
					}
					else if (token.mark === "^") {
						if (Array.isArray(val)) {
							val = !!val.length;
						}
						if (!val) {
							out.push( me.render_sub(token.children, scopes, out) );
						}
					}
					else if (token.mark === "#") {
						if (Array.isArray(val)) {
							for (j = 0; j < val.length; j++) {
								out.push( me.render_sub(token.children, scopes.concat([val[j]]), out) );
							}
						}
						else if (typeof val === "object" && val !== null) {
							out.push( me.render_sub(token.children, scopes.concat([val]), out) );
						}
						else if (val) {
							out.push( me.render_sub(token.children, scopes, out) );
						}
					}
				}
			}
		}

		/// 変数名 name を持つ変数がスコープリスト scopes の中に存在していればその値を返す
		/// 変数が存在しなければ undefined を返す
		///
		/// @param name: String
		/// @param scopes: [AnyObject] - スコープの配列。あとにある要素ほど優先度が高い
		/// @return value: AnyObject
		, resolve: function(name, scopes, undefined) {
			var me = this, parts, i, val, fns;

			// 配列の要素を表現
			if (name === ".") {
				return scopes[scopes.length - 1];
			}

			// フィルタの記述を分離
			fns = name.split(/(?:\s*\|\s*)/);
			name = fns.shift();

			// スコープ内の変数を走査する
			parts = name.split(/(?:\s*\.\s*)/);
			for (i = scopes.length - 1; i >= 0; i--) {
				if (scopes[i] && typeof scopes[i][parts[0]] !== "undefined") {
					val = scopes[i][parts[0]];
					if (typeof val === "function") {
						val = val.call(scopes[i]);
					}
					break;
				}
			}
			if (val === undefined) {
				return undefined;
			}

			// 変数名に . が含まれる場合、オブジェクト/配列の中を掘り下げる
			if (parts.length > 1) {
				parts.shift();
				val = this.resolve(parts.join("."), [val]);
			}

			// フィルタを適用
			fns.forEach(function(fn) {
				if (typeof me.filters[fn] === "function") {
					val = me.filters[fn](val);
				}
			});

			return val;
		}

		/// テンプレート文字列を解析して使用可能な状態にする
		/// 
		/// @throws TemplateError
		, compile: function() {
			this.tokenize();
			this.build();
		}

		/// テンプレート文字列を、文字列トークンとタグトークンに切り分ける
		///
		/// @throws TemplateError
		, tokenize: function() {
			var me = this, str, tokens, pos, len, inTag, delimiter, type, i;

			str = me.str;
			tokens = [];
			pos = 0;
			len = str.length;
			inTag = false;
			
			while (pos < len) {
				delimiter = inTag ? me.delimiterEnd : me.delimiterBegin;
				type = inTag ? Token.Tag : Token.Text;
				
				i = str.indexOf(delimiter, pos);
				if (i >= 0) {
					if (pos !== i) tokens.push(new Token(type, str.substring(pos, i)));
				} else {
					if (pos !== len) tokens.push(new Token(type, str.substring(pos, len)));
					break;
				}
				pos = i + delimiter.length;
				inTag = !inTag;
			}
			if (inTag) {
				throw new TemplateError("Unclosed tag");
			}
			me.tokens = tokens;
		}

		/// トークンに分けられたテンプレートをタグの情報に基づいて構造化する
		/// 
		/// @throws TemplateError
		, build: function() {
			var src, dest;
			src = this.tokens.slice(0); // 配列をコピー
			dest = [];
			this.build_sub(src, dest, null);
			this.tokens = dest;
		}

		/// @param src: [Token] - 入力トークン列, 常に同じオブジェクトを指す
		/// @param dest: [Token] - 出力トークン列, 入れ子関係 (# ^) がある場合は階層が変化する
		/// @param parent: Token? - 親トークン
		/// @throws TemplateError
		, build_sub: function(src, dest, parent) {
			var token;
			while (src.length) {
				token = src.shift();

				// 閉じタグ {{/cond}} の場合は親へ戻る
				if (token.type === Token.Tag &&
					token.mark === "/" &&
					parent && parent.str === token.str) {
					return;
				}

				// それ以外は同じ階層に追加
				dest.push(token);

				// 開くタグ {{#cond}} {{^cond}} の場合は子を設定して再帰呼び出し
				if (token.type === Token.Tag &&
					token.mark === "#" || token.mark === "^") {
					token.children = [];
					this.build_sub(src, token.children, token);
				}
			}

			// もし閉じタグがなくトークン列の終わりに到達してしまった場合、例外を投げる
			if (parent) {
				throw new TemplateError("Unclosed section \"" + parent.mark + parent.src + "\"");
			}
		}
	};

	/// ================================================================
	/// Expose

	BS.TemplateError = TemplateError;
	BS.Template = Template;
	
})(window);
