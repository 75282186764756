/// BS.Template.createFragment
(function(ns) {
	"use strict";

	/// @param templateString: String
	/// @param data: AnyObject
	/// @param filters: [string : function] - フィルタ関数のリスト
	/// @return Element
	ns.Template.createFragment = function(templateString, data, filters) {
		var template, wrapE;

		template = new BS.Template(templateString, filters || {});
		template.delimiterBegin = "{%";
		template.delimiterEnd = "%}";

		wrapE = ns.DOM.create("div");
		wrapE.innerHTML = template.render(data);

		return ns.DOM.fragment([].slice.apply(wrapE.childNodes));
	};
	
})(BS);
