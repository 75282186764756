if (!window.zzl) {
	window.zzl = {};
}
if (!zzl.uploader) {
	zzl.uploader = {};
}

zzl.q = function(query, el) {
	return (el || document).querySelector(query);
};
zzl.qs = function(query, el) {
	return Array.prototype.slice.call((el || document).querySelectorAll(query));
};
