(function() {
	"use strict";
	
	function testAnimationSupports() {
		var eventNames, el, prop;
		eventNames = {
			"animation": {
				start: "animationstart"
				, end: "animationend"
				, iteration: "animationiteration"
			}
			, "WebkitAnimation": {
				start: "webkitAnimationStart"
				, end: "webkitAnimationEnd"
				, iteration: "webkitAnimationIteration"
			}
		};
		el = document.createElement("test");
		for (prop in eventNames) {
			if (el.style[prop] !== undefined) {
				return eventNames[prop];
			}
		}
		return null;
	}

	BS.Env.CSSAnimation = testAnimationSupports();
})();
