(function(ns) {
	"use strict";

	var queue = [], running = false;

	function register(images) {
		if (Array.isArray(images)) {
			images.forEach(function(image) {
				registerItem(image);
			});
		}
		else {
			registerItem(images);
		}
		if (!running) {
			loadNext();
		}
	}

	function registerItem(image) {
		if (typeof image === "string") {
			queue.push(image);
		}
		else if (typeof image === "object") {
			if (typeof image.src === "string") {
				queue.push(image.src);
			}
		}
	}

	function loadNext() {
		if (queue.length < 1) {
			running = false;
			return;
		}
		running = true;

		var im = new Image, src = queue.shift();
		im.onload = function() {
			loadNext();
		};
		im.onerror = function() {
			loadNext();
		};
		im.src = src;
	}

	ns.Preloader = {
		register: register
	};
	
})(BS);
