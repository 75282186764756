/// BS.Paginator
/// ページ番号リンクを表現するクラス
(function(global) {
	"use strict";

	var BS = global.BS;

	/// Paginator の結果を返すときのあれ
	/// @param page: Int? - 0始まりのページ番号, 省略記号は null を設定する
	/// @param current: Boolean = false
	function PaginatorItem(page, current) {
		this.page = typeof page === "number" ? page : null;
		this.current = current || false;
	}

	PaginatorItem.prototype = {

		number: function() {
			return this.page === null ? null : this.page + 1;
		}
	};
	
	/// オプションリスト
	/// @param options: [String : AnyObject]
	function PaginatorOptions(options) {
		options = options || {}
		this.limit = options.limit || 10; // ページあたりの項目数
		this.term = options.term || 2; // はじめと終わりに表示する数 [1] [2] ... [ ] [*] [ ] ... [9] [10]
		this.around = options.around || 2; // 現在ページ両端に表示する数 ... [4] [5] [6*] [7] [8] ...
		this.itemClass = options.itemClass || PaginatorItem; // 結果をラッピングするクラス
	}

	/// @param options: PaginatorOptions?
	function Paginator(options) {

		// パラメータ
		this.options_ = new PaginatorOptions(options); // PaginatorOptions
		this.count_; // Int
		this.maxPage_; // Int
		this.thePage_; // Int

		// 出力のキャッシュ
		this.cached_ = false; // Bool
		this.pages_; // [PaginatorItem]?
		this.prev_; // PaginatorItem?
		this.next_; // PaginatorItem?
	}

	/// 項目数と現在位置から Paginator を生成
	/// @param count: Int
	/// @param options: PaginatorOptions?
	Paginator.initWithCount = function(count, options) {
		var paginator = new Paginator(options);
		paginator.setCount(count);
		paginator.setPage(0);
		return paginator;
	};

	Paginator.prototype = {

		/// 項目数を設定
		/// @param count: Int
		setCount: function(count) {
			this.count_ = count;
			this.maxPage_ = Math.ceil(count / this.options_.limit);
			this.cached_ = false;
		}

		/// 現在のページを設定
		/// @param page: Int
		, setPage: function(page) {
			this.thePage_ = page;
			this.cached_ = false;
		}

		/// ページリストを取得する
		/// @return [PaginatorItem]
		, pages: function() {
			if (!this.cached_) {
				this.build_();
			}
			return this.pages_;
		}

		/// 前のページの情報を取得する
		/// @return PaginatorItem
		, prev: function() {
			if (!this.cached_) {
				this.build_();
			}
			return this.prev_;
		}

		/// 次のページの情報を取得する
		/// @return PaginatorItem
		, next: function() {
			if (!this.cached_) {
				this.build_();
			}
			return this.next_;
		}

		/// 現在ページ (0始まり) を取得する
		/// @return Int
		, page: function() {
			return this.thePage_;
		}

		/// 現在ページ (1始まり) を取得する
		/// @return Int
		, currentPage: function() {
			return this.thePage_ + 1;
		}

		/// 最大ページ数 (1始まり) を取得する
		/// @return Int
		, maxPage: function() {
			return this.maxPage_ + 1;
		}

		/// 開始インデックス (1始まり) を取得する
		/// 「11～20件目」という表示に使う
		/// @return start: Int
		, start: function() {
			return this.thePage_ * this.options_.limit + 1;
		}

		/// 開始インデックス (0始まり) を取得する
		/// @return begin: Int
		, begin: function() {
			return this.thePage_ * this.options_.limit;
		}

		/// 終了インデックス (1始まり, または0始まりで終端を含めない) を取得する
		/// 「11～20件目」という表示に使う
		/// @return ends: Int
		, end: function() {
			return Math.min(this.count_, (this.thePage_ + 1) * this.options_.limit);
		}

		/// オブジェクトを用意する
		, build_: function() {
			var term, around, Item, maxPage, thePage, divs, pages, i, p;

			// 総件数 0 の場合は
			if (this.count_ < 1) {
				this.pages_ = null;
				this.prev_ = null;
				this.next_ = null;
				this.cached_ = true;
			}

			term = this.options_.term;
			around = this.options_.around;
			Item = this.options_.itemClass;
			maxPage = this.maxPage_;
			thePage = this.thePage_;
			divs = [];

			// ページが少ない場合
			if (maxPage < 3 + 2*(term + around)) {
				divs.push({ begin: 0, end: maxPage });
			}
			// 前半ツメ
			else if (thePage <= term + around + 1) {
				divs.push({ begin: 0, end: term + 2*around + 2 });
				divs.push({ begin: maxPage - term, end: maxPage });
			}
			// 後半ツメ
			else if (thePage >= maxPage - term - around - 2) {
				divs.push({ begin: 0, end: term });
				divs.push({ begin: maxPage - term - 2*around - 2, end: maxPage });
			}
			// 中間
			else {
				divs.push({ begin: 0, end: term });
				divs.push({ begin: thePage - around, end: thePage + around + 1 });
				divs.push({ begin: maxPage - term, end: maxPage });
			}

			pages = [];
			for (i = 0; i < divs.length; i++) {
				if (i !== 0) {
					pages.push(new Item(null));
				}
				for (p = divs[i].begin; p < divs[i].end; p++) {
					pages.push(new Item(p, p === thePage));
				}
			}
			this.pages_ = pages;

			if (thePage === 0) {
				this.prev_ = null;
			} else {
				this.prev_ = new Item(thePage - 1);
			}

			if (thePage === maxPage - 1) {
				this.next_ = null;
			} else {
				this.next_ = new Item(thePage + 1);
			}

			this.cached_ = true;
		}
	};

	/// ================================================================
	/// Expose

	BS.PaginatorOptions = PaginatorOptions;
	BS.PaginatorItem = PaginatorItem;
	BS.Paginator = Paginator;
	
})(window);
