(function() {
	"use strict";

	const $ = jQuery;

	if (!window.BS || !$) {
		return;
	}

	var instances = [], resizeTimer;

	function AsyncContact() {
		var me = this;
		me.$form = null;
		me.$body = null;
		me.$current = null;
		me.$input = null;
		me.$confirm = null;
		me.$complete = null;
		me.postURL = null;
		me.formData = null;
		me.tplInput = null;
		me.tplConfirm = null;
		me.tplComplete = null;
		me.init.apply(me, arguments);
		me.networkActive = false;
	}

	AsyncContact.prototype = {

		init: function(dom) {
			var me = this;
			instances.push(me);
			me.$form = $(dom);

			me.postURL = me.$form.attr("action");
			me.$body = me.$form.find(".bs-async-contact-body");
			me.tplInput = me.$form.find(".tpl-input").html() || "";
			me.tplConfirm = me.$form.find(".tpl-confirm").html() || "";
			me.tplComplete = me.$form.find(".tpl-complete").html() || "";

			me.$body.empty();

			me.$input = $("<div>").addClass("bs-async-contact-part").html(BS.tmpl(me.tplInput, {})).appendTo(me.$body);
			me.$confirm = $("<div>").addClass("bs-async-contact-part").hide().appendTo(me.$body);
			me.$complete = $("<div>").addClass("bs-async-contact-part").hide().appendTo(me.$body);
			me.$current = me.$input;

			me.fitToPanel();
			me.attachEvents();
		}

		, attachEvents: function() {
			var me = this;
			me.$form.on(BS.Event.Tap, ".bs-async-contact-action", function(event) {
				me.act(event);
			});
		}

		, fitToPanel: function() {
			var me = this;
			if (me.$body.height() < me.$current.height()) {
				me.$body.height(me.$current.height());
			}
		}

		, switchPanel: function($panel) {
			var me = this;
			if (me.$current !== $panel) {
				me.$current.fadeOut();
				$panel.fadeIn();
				me.$current = $panel;
				me.fitToPanel();
			}
		}

		, act: function(event) {
			var me = this, $target, action;
			$target = $(event.target).closest(".bs-async-contact-action");
			action = $target.attr("data-action");

			switch (action) {
			default:
				return;

			case "confirm":
				me.confirm();
				break;

			case "back":
				me.back();
				break;

			case "send":
				me.send();
				break;
			}
			event.preventDefault();
		}

		, confirm: function() {
			var me = this;

			if (me.networkActive) {
				return;
			}
			me.setNetworkActive(true);

			me.formData = BS.Form.values(me.$form);
			me.formData.send = 0;
			$.ajax(me.postURL, {
				type: "POST"
				, dataType: "json"
				, data: me.formData

			}).done(function(result) {
				me.setNetworkActive(false);
				if (result.errors) {
					me.showInputAndErrors(result.errors);
				} else {
					me.$confirm.html(BS.tmpl(me.tplConfirm, me.getViewData(result.data)));
					me.switchPanel(me.$confirm);
					me.scrollToPanelTop();
				}
			}).fail(function() {
				me.setNetworkActive(false);
				me.showNetworkError();
			});
		}

		, back: function() {
			var me = this;
			me.$input.find(".has-error").removeClass("has-error");
			me.$input.find(".form-error").remove();
			me.switchPanel(me.$input);
		}

		, send: function() {
			var me = this;

			if (me.networkActive) {
				return;
			}
			me.setNetworkActive(true);

			me.formData.send = 1;
			$.ajax(me.postURL, {
				type: "POST"
				, dataType: "json"
				, data: me.formData

			}).done(function(result) {
				me.setNetworkActive(false);
				if (result.errors) {
					me.showInputAndErrors(result.errors);
				} else {
					me.$complete.html(BS.tmpl(me.tplComplete, me.getViewData(me.formData)));
					me.switchPanel(me.$complete);
					me.scrollToPanelTop();
				}
			}).fail(function() {
				me.setNetworkActive(false);
				me.showNetworkError();
			});
		}

		, setNetworkActive: function(active) {
			var me = this;

			me.networkActive = active;
			me.$body.find(".bs-async-contact-action")
				.prop("disabled", active)
				[active ? "addClass" : "removeClass"]("submitting");
		}

		, showInputAndErrors: function(errors) {
			var me = this, key, $el, $controls, $error;

			me.$input.find(".form-error").remove();
			me.$input.find(".has-error").removeClass("has-error");

			for (key in errors) {
				$el = me.$input.find("[name='" + key + "'], [name*='[" + key + "]']").eq(0);
				if ($el.length) {
					$error = $("<div>").addClass("form-error").text(errors[key]);
					$controls = $el.closest(".controls");
					if ($controls.length) {
						$controls.append($error);
					} else {
						$el.after($error);
					}
					$el.closest(".form-group").addClass("has-error");
				}
			}

			me.fitToPanel();
			me.switchPanel(me.$input);
		}

		, showNetworkError: function() {
			alert("データ通信に失敗しました");
		}

		, scrollToPanelTop: function() {
			var $el = this.$body, top;
			top = $el.offset().top;
			top -= 50;
			if (top < 0) top = 0;
			smoothScrollTo(top);
		}

		, getViewData: function(formData) {
			var key, m, viewData;

			// Contact[name] -> name
			viewData = {};
			for (key in formData) {
				if ((m = key.match(/^\w+\[([\w$-]+)\]$/))) {
					viewData[m[1]] = formData[key];
				}
				else {
					viewData[key] = formData[key];
				}
			}
			return viewData;
		}

	};

	function onresize() {
		for (var i = 0; i < instances.length; i++) {
			instances[i].fitToPanel();
		}
	}

	$(function() {
		$(".bs-async-contact").each(function() {
			new AsyncContact(this);
		});

		$(window).on("resize", function() {
			clearTimeout(resizeTimer);
			resizeTimer = setTimeout(onresize, 50);
		});
	});

})();
