(function(ns) {
	"use strict";

	var htmlE;

	BS.DOMReady(function() {
		htmlE = document.body.parentNode;
	});

	// targetTop, targetLeft まで絹のようになめらかにスクロールさせる
	function toPosition(targetTop, targetLeft) {
		var startTop = htmlE.scrollTop || document.body.scrollTop
		, startLeft = htmlE.scrollLeft || document.body.scrollLeft
		, deltaY = (targetTop == null) ? 0 : targetTop - startTop
		, deltaX = (targetLeft == null) ? 0 : targetLeft - startTop
		, duration = 400
		, startTime = Date.now()
		;

		function toPosition_() {
			var frac = Math.min(1, (Date.now() - startTime) / duration);
			window.scrollTo(startLeft + deltaX * frac, startTop + deltaY * frac);
			if (frac < 1) {
				setTimeout(toPosition_, 10);
			}
		}

		toPosition_();
	}

	function toElement(element, offsetY, offsetX) {
		var position = offset(element), top, left;

		offsetY = offsetY || 0;
		offsetX = offsetX || 0;

		if (position) {
			top = position.top - offsetY;
			if (top < 0) {
				top = 0;
			}
			left = position.left - offsetX;
			if (left < 0) {
				left = 0;
			}
			toPosition(top, left);
		}
	}

	function offset(element) {
		var rect, win;

		if (!element.getClientRects().length) {
			return { top: 0, left: 0 };
		}

		rect = element.getBoundingClientRect();
		win = element.ownerDocument.defaultView;
		return {
			top: rect.top + win.pageYOffset,
			left: rect.left + win.pageXOffset
		};
	}
	
	ns.Scroll = {
		toPosition: toPosition
	,	toElement: toElement
	};
	
})(BS);
