(function(global) {
	"use strict";

	var UNDEF,
	
	Entities = global.BS.Entities,
	
	d = document,
	
	/// @param needle: any
	/// @param haystack: [any]
	inArray = function(needle, haystack) {
		var i;
		for (i = 0; i < haystack.length; i++) {
			if (needle === haystack[i]) {
				return true;
			}
		}
		return false;
	},
	
	DOM = {
	
		/// @param query: string
		/// @param E: Element
		/// @return Element?
		q: function(query, E) {
			if (!E) {
				E = d;
			}
			if (!E.querySelector) {
				return null;
			}
			return E.querySelector(query);
		},

		/// @param query: string
		/// @param E: Element
		/// @return NodeList?
		qs: function(query, E) {
			if (!E) {
				E = d;
			}
			if (!E.querySelectorAll) {
				return null;
			}
			return E.querySelectorAll(query);
		},

		/// @param node: Node?
		/// @return boolean
		isNode: function(node) {
			return node && typeof node.nodeType === "number";
		},
	
		/// @param node: Node?
		/// @return boolean
		isElement: function(node) {
			return node && node.nodeType === 1;
		},

		/// @param node: Node?
		/// @return boolean
		isTextNode: function(node) {
			return node && node.nodeType === 3;
		},

		/// @param node: Node?
		/// @return boolean
		isCommentNode: function(node) {
			return node && node.nodeType === 8;
		},

		/// @param node: Node?
		/// @return boolean
		isFragment: function(node) {
			return node && node.nodeType === 11;
		},
	
		/// @param query: string | [string]
		/// @param E: Element
		/// @return boolean
		is: function(query, E) {
			var matches, i;
	
			if (Array.isArray(query)) {
				query = query.join(", ");
			}
	
			matches = E.parentNode.querySelectorAll(query);
			for (i = matches.length - 1; i >= 0; i--) {
				if (matches[i] === E) {
					return true;
				}
			}
			return false;
		},
	
		/// NOTE: NodeList が入力された場合でも Node の配列を返す点に注意
		/// @param query: string
		/// @param Es: NodeList | [Node]
		/// @return [Node]
		filter: function(query, Es) {
			var filteredEs, E, i;
	
			for (filteredEs = [], i = 0; i < Es.length; i++) {
				E = Es[i];
				if (E.parentNode && [].indexOf.call(E.parentNode.querySelectorAll(query), E) >= 0) {
					filteredEs.push(E);
				}
			}
			return filteredEs;
		},
	
		/// node の祖先のうち、node に一番近い query にマッチする要素を返す
		/// @param query: string
		/// @param node: Node
		/// @return Element?
		closest: function(query, node) {
	
			if (Array.isArray(query)) {
				query = query.join(", ");
			}
	
			if (typeof query !== "string") {
				return DOM.closestNode(query, node);
			}
	
			// テキストノードやコメントノードに対しても取得できるようにする
			if (DOM.isTextNode(node) || DOM.isCommentNode(node)) {
				node = node.parentNode;
			}
	
			// ネイティブの closest メソッドを使う
			if (window.Element && Element.prototype.closest) {
				if (!node.closest) {
					return null;
				}
				return node.closest(query);
			}
	
			// Polyfill for IE11
			var matches, i, el;
			matches = (node.document || node.ownerDocument).querySelectorAll(query);
			el = node;
			do {
				i = matches.length;
				while (--i >= 0 && matches[i] !== el) {}
			} while (i < 0 && (el = el.parentNode));
			return el;
		},
	
		/// node の祖先に targetNode があれば targetNode を返す
		/// @param targetNode: Node
		/// @param node: Node
		/// @return Node?
		closestNode: function(targetNode, node) {
	
			while (node) {
				if (node === targetNode) {
					return node;
				}
				node = node.parentNode;
			}
	
			return null;
		},
	
		/// 要素生成用のセレクタを要素名とクラス、IDに分ける。create() で使用する
		/// @param selector: string
		/// @param attributes: [string : string]?
		/// @return [tagName: string, attributes: object]
		parseSelector: function(selector, attributes) {
			var tagName, newAttributes, i, pos, mark, c, attr;
	
			newAttributes = {};
			if (attributes != UNDEF) {
				Object.keys(attributes).forEach(function(key) {
					newAttributes[key] = attributes[key];
				});
			}
	
			for (i = 0, pos = 0; i <= selector.length; i++) {
	
				// 範囲外の場合、charAt() は "" を返す
				c = selector.charAt(i);
				if (c === "#" || c === "." || c === "") {
					if (i === pos) {
						throw new Error("Invalid selector: " + selector);
					}
					attr = selector.substring(pos, i);
					switch (mark) {
					case "#":
						newAttributes.id = attr;
						break;
					case ".":
						newAttributes["class"] = newAttributes["class"] ? newAttributes["class"] + " " + attr : attr;
						break;
					default:
						tagName = attr;
						break;
					}
					mark = c;
					pos = i + 1;
				}
			}
	
			if (tagName == UNDEF) {
				throw new Error("Invalid selector: " + selector);
			}
	
			return [tagName, newAttributes];
		},
	
		/// 要素ノードを作成する
		/// @param selector: string
		/// @param attributes: [string : string]?
		/// @param children: [Node]?
		/// @return Element
		create: function(selector, attributes, children) {
			var E, parsed;

			if (attributes != UNDEF && typeof attributes !== 'object') throw new Error('Invalid argument: attributes must be object');
			if (children != UNDEF && !Array.isArray(children)) throw new Error('Invalid argument: children must be array');
	
			parsed = DOM.parseSelector(selector, attributes);
	
			E = d.createElement(parsed[0]);
			DOM.modify(E, parsed[1]);
	
			if (children != UNDEF) {
				children.forEach(function(child) {
					if (typeof child === "string") {
						child = DOM.text(child);
					}
					if (child != null) {
						E.appendChild(child);
					}
				});
			}
	
			return E;
		},
	
		/// テキストノードを作成する
		/// @param content: string
		/// @return TextNode
		text: function(content) {
			return d.createTextNode(content);
		},

		/// ドキュメントフラグメントを作成する
		/// @param children: [Node]?
		/// @return Element
		fragment: function(children) {
			var E;
	
			E = d.createDocumentFragment();
	
			if (children != UNDEF) {
				children.forEach(function(child) {
					if (typeof child === "string") {
						child = DOM.text(child);
					}
					E.appendChild(child);
				});
			}
	
			return E;
		},
	
		/// @param E: Element
		/// @param attributes: [string : string]?
		modify: function(E, attributes) {
			if (attributes != UNDEF) {
				if (typeof attributes !== 'object') throw new Error('Invalid argument.');

				Object.keys(attributes).forEach(function(attr) {
					if (attr === "style") {
						DOM.setStyles(E, attributes[attr]);
						return;
					}
					E.setAttribute(attr, attributes[attr]);
				});
			}
	
			return E;
		},
	
		/// @param node: Node
		/// @param parentNode: Node
		append: function(node, parentNode) {
			parentNode.appendChild(node);
		},
	
		/// @param node: Node
		/// @param referenceNode: Node
		insertBefore: function(node, referenceNode) {
			referenceNode.parentNode.insertBefore(node, referenceNode);
		},
	
		/// @param node: Node
		/// @param referenceNode: Node
		insertAfter: function(node, referenceNode) {
			var parent, nextSibling;
	
			parent = referenceNode.parentNode;
			nextSibling = referenceNode.nextSibling;
	
			if (nextSibling) {
				parent.insertBefore(node, nextSibling);
			} else {
				parent.appendChild(node);
			}
		},
	
		/// @param oldNode: Node
		/// @param newNode: Node
		/// @return replacedNode: Node
		replace: function(oldNode, newNode) {
			if (!oldNode || !oldNode.parentNode) {
				return null;
			}
			return oldNode.parentNode.replaceChild(newNode, oldNode);
		},

		/// 子孫ノードをすべて削除する
		empty: function(node) {
			while (node.firstChild) {
				node.removeChild(node.firstChild);
			}
		},
	
		/// targetE を wrappingE で外包する
		/// @param targetE: Element
		/// @param wrappingE: Element
		/// @return wrappingE: Element
		wrap: function(targetE, wrappingE) {
			targetE.parentNode.insertBefore(wrappingE, targetE);
			wrappingE.appendChild(targetE);
			return wrappingE;
		},
	
		/// wrappingE に囲まれている要素を wrappingE の外に出し、wrappingE を削除する
		/// @param wrappingE: Element
		unwrap: function(wrappingE) {
			var parentE;
	
			parentE = wrappingE.parentNode;
			if (!parentE) {
				return;
			}
	
			while (wrappingE.firstChild) {
				parentE.insertBefore(wrappingE.firstChild, wrappingE);
			}
	
			parentE.removeChild(wrappingE);
		},
	
		/// @param node: Element
		/// @preturn removedNode: Element?
		remove: function(node) {
			if (!node.parentNode) {
				return null;
			}
			node.parentNode.removeChild(node);
			return node;
		},
	
		/// @param node: Element
		/// @param styles: [string : string]
		setStyles: function(node, styles) {
			Object.keys(styles).forEach(function(prop) {
				node.style[prop] = styles[prop];
			});
		},
	
		/// @param node: Element
		/// @param aClass: string
		addClass: function(node, aClass) {
			var classes, adds, changed = false;
	
			classes = (node.className || "").split(/\s+/);
	
			adds = aClass.split(/\s+/);
			adds.forEach(function(c) {
				if (!inArray(c, classes)) {
					classes.push(c);
					changed = true;
				}
			});
	
			if (!changed) {
				return;
			}
	
			node.className = classes.join(" ");
		},
	
		/// @param node: Element
		/// @param aClass: string
		removeClass: function(node, aClass) {
			var classes, removes, i, changed = false;
	
			removes = aClass.split(/\s+/);
	
			classes = (node.className || "").split(/\s+/);
			for (i = 0; i < classes.length; i++) {
				if (classes[i] === "" || inArray(classes[i], removes)) {
					changed = true;
					classes.splice(i, 1);
					i--;
				}
			}
	
			if (!changed) {
				return;
			}
	
			if (classes.length > 0) {
				node.className = classes.join(" ");
			} else {
				node.removeAttribute("class");
			}
		},

		/// @param node: Element
		/// @param aClass: string
		/// @return boolean
		hasClass: function(node, aClass) {
			var classes, search;

			if (aClass === "") {
				return false;
			}
	
			classes = " " + (node.className || "") + " ";
			search = " " + aClass + " ";
			return classes.indexOf(search) >= 0;
		},
	
		getOuterHTML: function(node) {
			var tmpE;
	
			if (node.nodeType === 1 && "outerHTML" in node) {
				return node.outerHTML;
			}
	
			tmpE = DOM.create("div");
			tmpE.appendChild(node.clone(true));
			return tmpE.innerHTML;
		},
	
		/// @param text: string
		/// @return string
		encode: Entities.encode,
	
		/// @param text: string
		/// @return string
		decode: Entities.decode
	
	};
	
	global.BS.DOM = DOM;

})(window);
