/* global BS */
BS.DOMReady(function () {
	'use strict';

	[].forEach.call(document.querySelectorAll('.bs-ed-replace'), function (wrapE) {
		new BS.Editor(wrapE, {
			// blocks: ["text", "floatPicture", "picture", "youtube", "table", "files", "map", "linkButton", "html"],
			blocks: ["text", "picture", "youtube", "table", "gmap", "linkButton"],
			toolbar: [['h1', 'h2'], ['center', 'right'], ['ul', 'ol'], ['bold', 'color'], ['link']],
			// colors: ["#333333", "#888888", "#bd2d2d", "#1c65ad","#bca736", "#26a14e", "#f27171", "#699ce7"],
			fileSelectHandler: function (fileView, cb) {
				new BS.FileSelect(fileView, cb)
			}
		})
	})
})
