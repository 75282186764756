/*
	Canvas 描画ライブラリ
*/
(function(ns) {
	"use strict";

	const $ = jQuery;

	/// 作成する canvas の最大ピクセル数。
	/// デバイス依存の設定値で、メモリ不足によりブラウザが強制終了するのを防ぐ。
	/// @let OptimalMaxPixels: number
	var OptimalMaxPixels = 2400 * 1800;

	/// 許容する Number 型の誤差
	var AllowedError = 0.01;

	/// 新しい canvas を作成する
	/// @return HTMLCanvasElement
	function newCanvas() {
		return document.createElement("canvas");
	}

	/// 画像を orientation に応じて canvas に描画する。
	/// canvas が null の場合は新しい canvas を作成する。
	/// cropInfo の座標は記録されている状態の座標ではなく、正立状態にしたときの座標を指定する。
	/// @param canvas: HTMLCanvasElement?
	/// @param src: HTMLImageElement || HTMLCanvasElement
	/// @param cropInfo: {
	/// 	orientation: number?, // 画像の向き
	/// 	sx: number?, sy: number?, // 切り抜きの始点
	/// 	sw: number?, sh: number?, // 切り抜きサイズ
	/// 	dx: number?, dy: number?, // 出力の始点
	/// 	dw: number?, dh: number?, // 出力サイズ
	/// 	maxPixels: number?, // 最大ピクセル数
	///     allowTransparency: boolean? // 透過を許可
	/// }
	/// @return {canvas: HTMLCanvasElement, width: number, height: number}
	function draw(canvas, src, cropInfo) {
		var ci, orientation, ow, oh, ew, eh, scale, ctx, rci, altCanvas, tmpCanvas, flip;

		canvas = canvas || newCanvas();
		ci = cropInfo || {};
		orientation = ci.orientation || 1;

		// 記録されている状態の画像の幅、高さ
		ow = src.width;
		oh = src.height;

		// 正立状態にしたときの画像の幅、高さ
		ew = (orientation < 5) ? src.width : src.height;
		eh = (orientation < 5) ? src.height : src.width;

		// cropInfo のエイリアス。デフォルト値を設定する
		ci = $.extend({
			// 記録画像のサイズ
			ow: ow,
			oh: oh,
			// 正立画像のサイズ
			ew: ew,
			eh: eh,
			// 切り抜き元原点 (正立)
			sx: 0,
			sy: 0,
			// 切り抜き元サイズ (正立)
			sw: ew,
			sh: eh,
			// 出力原点 (正立)
			dx: 0,
			dy: 0,
			// 最大ピクセル数
			maxPixels: Infinity
		}, ci);
		ci = $.extend({
			// 切り抜き後サイズ (正立)
			dw: ci.sw,
			dh: ci.sh
		}, ci);

		// 出力画像のピクセル数を maxPixels 以下に制限する
		scale = Math.sqrt((ci.dw * ci.dh) / ci.maxPixels);
		if (scale > 1) {
			ci.dw /= scale;
			ci.dh /= scale;
		}

		// sw >= 2dw || sh >= 2dh の場合は段階的縮小を行う
		// cf. Canvas drawImage() で画像を縮めすぎるとギザギザする問題を解消する
		// http://jsdo.it/21f/nvIM
		ci.dw_ = ci.dw = Math.round(ci.dw);
		ci.dh_ = ci.dh = Math.round(ci.dh);
		if (ci.sw >= 2 * ci.dw || ci.sh >= 2 * ci.dh) {
			ci.dw = getOptimalSize(ci.sw, ci.dw_);
			ci.dh = getOptimalSize(ci.sh, ci.dh_);
		}

		// キャンバスの幅・高さを設定する
		canvas.width = ci.dw;
		canvas.height = ci.dh;

		// 画像の向きを設定し、描画
		ctx = canvas.getContext("2d");
		ctx.clearRect(0, 0, canvas.width, canvas.height);
		if (!ci.allowTransparency) {
			ctx.fillStyle = "#ffffff";
			ctx.fillRect(0, 0, canvas.width, canvas.height);
		}
		ctx.save();
		rci = setOrientationContext(ctx, ci, orientation);
		rci = adjustStartPoint(rci, src); // 座標の指定が入力画像の範囲外になる場合に補正
		ctx.drawImage(src, rci.sx, rci.sy, rci.sw, rci.sh, rci.dx, rci.dy, rci.dw, rci.dh);
		ctx.restore();

		// 段階的縮小を行う場合は最終的なサイズになるまで縮小処理を行う
		if (ci.dw_ < ci.dw || ci.dh_ < ci.dh) {
			// canvas と altCanvas とで交互に縮小画像を描画していく。
			// flip にはもとの canvas と altCanvas が入れ替わっているかどうかを設定する。
			altCanvas = newCanvas();
			flip = false;

			while (ci.dw_ < ci.dw || ci.dh_ < ci.dh) {
				ci.sw = ci.dw;
				ci.sh = ci.dh;
				ci.dw = (ci.dw_ < ci.dw) ? getOptimalSize(ci.sw, ci.dw_) : ci.dw_;
				ci.dh = (ci.dh_ < ci.dh) ? getOptimalSize(ci.sh, ci.dh_) : ci.dh_;
				ctx = altCanvas.getContext("2d");
				altCanvas.width = ci.dw;
				altCanvas.height = ci.dh;
				ctx.clearRect(0, 0, ci.dw, ci.dh);
				ctx.drawImage(canvas, 0, 0, ci.sw, ci.sh, 0, 0, ci.dw, ci.dh);

				// canvas と altCanvas を入れ替え
				tmpCanvas = canvas;
				canvas = altCanvas;
				altCanvas = tmpCanvas;
				flip = !flip;
			}

			// canvas と altCanvas が入れ替わっている場合、内容を canvas にコピーする
			if (flip) {
				ctx = altCanvas.getContext("2d");
				altCanvas.width = ci.dw_;
				altCanvas.height = ci.dh_;
				ctx.drawImage(canvas, 0, 0);
				canvas = altCanvas;
			}
		}

		return {
			canvas: canvas
			, width: canvas.width
			, height: canvas.height
		};
	}

	/// 画像を縮小する際、幅・高さ
	function getOptimalSize(orig, target) {
		return Math.round(target * Math.pow(2, Math.ceil(Math.log(orig/target) / Math.LN2) - 1));
    }

	/// 画像の向きを設定する
	/// @param ctx: CanvasRenderingContext2D
	/// @param ci: object - cropInfo (cf. draw())
	/// @param orientation: number
	/// @return rci: object - Rotated cropInfo
	function setOrientationContext(ctx, ci, orientation) {
		switch (orientation) {
		case 2:
			// horizontal flip
			ctx.translate(ci.dw, 0);
			ctx.scale(-1, 1);
			return {
				sx: (ci.ew - ci.sw - ci.sx),
				sy: ci.sy,
				sw: ci.sw,
				sh: ci.sh,
				dx: ci.dx,
				dy: ci.dy,
				dw: ci.dw,
				dh: ci.dh
			};
		case 3:
			// 180 rotate left
			ctx.translate(ci.dw, ci.dh);
			ctx.rotate(Math.PI);
			return {
				sx: (ci.ew - ci.sw - ci.sx),
				sy: (ci.eh - ci.sh - ci.sy),
				sw: ci.sw,
				sh: ci.sh,
				dx: ci.dx,
				dy: ci.dy,
				dw: ci.dw,
				dh: ci.dh
			};
		case 4:
			// vertical flip
			ctx.translate(0, ci.dh);
			ctx.scale(1, -1);
			return {
				sx: ci.sx,
				sy: (ci.eh - ci.sh - ci.sy),
				sw: ci.sw,
				sh: ci.sh,
				dx: ci.dx,
				dy: ci.dy,
				dw: ci.dw,
				dh: ci.dh
			};
		case 5:
			// vertical flip + 90 rotate right
			ctx.rotate(0.5 * Math.PI);
			ctx.scale(1, -1);
			return {
				sx: ci.sy,
				sy: ci.sx,
				sw: ci.sh,
				sh: ci.sw,
				dx: ci.dx,
				dy: ci.dy,
				dw: ci.dh,
				dh: ci.dw
			};
		case 6:
			// 90 rotate right
			ctx.rotate(0.5 * Math.PI);
			ctx.translate(0, -ci.dw);
			return {
				sx: ci.sy,
				sy: (ci.ew - ci.sw - ci.sx),
				sw: ci.sh,
				sh: ci.sw,
				dx: ci.dx,
				dy: ci.dy,
				dw: ci.dh,
				dh: ci.dw
			};
		case 7:
			// horizontal flip + 90 rotate right
			ctx.rotate(0.5 * Math.PI);
			ctx.translate(ci.dh, -ci.dw);
			ctx.scale(-1, 1);
			return {
				sx: (ci.eh - ci.sh - ci.sy),
				sy: (ci.ew - ci.sw - ci.sx),
				sw: ci.sh,
				sh: ci.sw,
				dx: ci.dx,
				dy: ci.dy,
				dw: ci.dh,
				dh: ci.dw
			};
		case 8:
			// 90 rotate left
			ctx.rotate(-0.5 * Math.PI);
			ctx.translate(-ci.dh, 0);
			return {
				sx: (ci.eh - ci.sh - ci.sy),
				sy: ci.sx,
				sw: ci.sh,
				sh: ci.sw,
				dw: ci.dh,
				dh: ci.dw
			};
		default:
			return {
				sx: ci.sx,
				sy: ci.sy,
				sw: ci.sw,
				sh: ci.sh,
				dx: ci.dx,
				dy: ci.dy,
				dw: ci.dw,
				dh: ci.dh
			};
		}
	}

	/// 始点が負の場合、あるいは幅が入力画像の大きさを超える場合、始点を 0 として出力先の始点を調整する
	/// @param cropInfo: (cropInfo)
	/// @param src: HTMLImageElement || HTMLCanvasElement
	function adjustStartPoint(cropInfo, src) {
		var ci = $.extend(cropInfo, {});

		if (ci.sx < 0 || ci.sy < 0 || ci.sw > src.width || ci.sh > src.height) {
			//alert(JSON.stringify(ci, null, 4));
		}

		if (ci.sx < 0 || ci.sx + ci.sw > src.width) {
			ci.dx = ci.dx - ci.sx * ci.dw/ci.sw;
			ci.dw = ci.dw - (ci.sw - src.width) * ci.dw/ci.sw;
			ci.sx = 0;
			ci.sw = src.width;
		}
		if (ci.sy < 0 || ci.sy + ci.sh > src.height) {
			ci.dy = ci.dy - ci.sy * ci.dh/ci.sh;
			ci.dh = ci.dh - (ci.sh - src.height) * ci.dh/ci.sh;
			ci.sy = 0;
			ci.sh = src.height;
		}
		return ci;
	}

	ns.canvas = {
		create: newCanvas
	,	draw: draw
	,	OptimalMaxPixels: OptimalMaxPixels
	};

})(zzl.uploader);
