(function(ns) {

	var rs = document.readyState;
	var ready = (rs === "complete" || rs === "interactive");

	function addDOMReadyListener(listener) {
		if (ready) {
			setTimeout(listener);
		} else {
			document.addEventListener("DOMContentLoaded", listener, {once: true});
		}
	}

	document.addEventListener("DOMContentLoaded", function() {
		ready = true;
	}, {once: true});

	ns.DOMReady = addDOMReadyListener;

})(BS);
