(function (ns) {
	"use strict";

	const $ = jQuery;

	$(function () {
		zzl.qs(".zzl-input-image").forEach(function (el) {
			const useDataURI = el.classList.contains('--data-uri');
			const input = el.querySelector('input');
			const value = input ? input.value : '';
			if (useDataURI && input) {
				// 初期値からプレビュー表示する部分をスキップ
				input.value = '';
			}

			const uploader = new ns.ImageUploader(el);

			// Data URI で直接値を送信
			if (useDataURI) {
				if (value) {
					// プレビューを設定
					uploader.setPreviewImage(value)
				}

				// 形式は JPEG 固定とする
				uploader.getOutputType = () => 'image/jpeg'

				uploader.startUpload = (_, dataURI) => {
					uploader.origInputE.val(dataURI);
					uploader.setProgressVisible(false);
					uploader.resetInput();
				}
			}
		});
	});

})(zzl.uploader);
