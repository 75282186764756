// checkbox-toggle.js
jQuery(function() {
	const $ = jQuery;

	var groupSelector = ".checkbox-group",
	togglerClass = "checkbox-group-toggle-all",
	togglerSelector = "." + togglerClass,
	checkboxSelector = "input[type='checkbox']";

	$(document).on("click", groupSelector, function(event) {
		var targetE, groupE, togglerE, isToggler, checkboxes;
		targetE = $(event.target).closest("label, " + checkboxSelector);
		groupE = targetE.closest(groupSelector);
		togglerE = groupE.find(checkboxSelector + togglerSelector);
		isToggler = targetE.hasClass(togglerClass);
		if (targetE.length < 1 || groupE.length < 1) {
			return;
		}
		if (isToggler) {
			groupE.find(checkboxSelector).prop("checked", togglerE.prop("checked"));
		} else {
			checkboxes = groupE.find(checkboxSelector).not(togglerSelector);
			togglerE.prop("checked", checkboxes.filter(":checked").length === checkboxes.length);
		}
	});
});
