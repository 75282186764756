//! jquery.dtpicker
jQuery(function() {
	const $ = jQuery;
	var locale = "ja";

	if (! $.fn.dtpicker) return;

	$("input").filter("[type='datetime-local'], [type='datetime'], [type='date']").each(function() {
		var opts = { locale: locale }, attr;
		if ($(this).attr("type") === "date") { opts.dateOnly = true; }
		if (attr = $(this).attr("min")) { opts.minDate = attr; }
		if (attr = $(this).attr("max")) { opts.maxDate = attr; }

		try { this.type = "text"; } catch(e) {}
		var $wrap = $("<label/>").addClass("date-input");
		$wrap.append($("<span/>").addClass("glyphicon glyphicon-calendar"));
		$(this).before($wrap).appendDtpicker(opts);
		$wrap.prepend(this);
	});
});
