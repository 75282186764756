(function() {

	if (!BS || !BS.GoogleMaps) return;

	BS.GoogleMaps.ready(initFitBoundsPerfectly);

	function initFitBoundsPerfectly() {

		/// 範囲にジャストフィット
		google.maps.Map.prototype.fitBoundsPerfectly = function fitBoundsPerfectly(aBounds) {
			var mapBounds, mapSW, mapNE, mapDLat, mapDLng, aSW, aNE, dLat, dLng, ratio, dZoom;

			mapBounds = this.getBounds();
			mapSW = mapBounds.getSouthWest();
			mapNE = mapBounds.getNorthEast();
			mapDLat = mapNE.lat() - mapSW.lat();
			mapDLng = mapNE.lng() - mapSW.lng();

			aSW = aBounds.getSouthWest();
			aNE = aBounds.getNorthEast();
			dLat = aNE.lat() - aSW.lat();
			dLng = aNE.lng() - aSW.lng();

			ratio = Math.max(dLat / mapDLat, dLng / mapDLng);
			dZoom = -Math.ceil(Math.log(ratio) / Math.log(2));

			if (dZoom) {
				this.setZoom(this.getZoom() + dZoom);
			}
			this.panTo(aBounds.getCenter());
		};
	}
	
})();
