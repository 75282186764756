(function(ns) {
	"use strict";

	var Cropper;

	Cropper = function Cropper() {
		this.init.apply(this, arguments);
	};

	Cropper.prototype = {
		
		/// @param src: string
		/// @param options: {
		/// 	size: {width: number, height: number},
		/// 	orientation: number,
		/// 	maximumPixelCount: number,
		///     allowTransparency: boolean
		/// }
		/// @param callback: function(image: {canvas: HTMLCanvasElement, width: number, height: number})
		init: function(src, options, callback) {
			var me = this;

			me.src = src;
			me.options = options;
			me.callback = callback;

			me.loadImage();
		}

		, loadImage: function() {
			var me = this, image;
			
			image = new Image;
			image.onload = function() {
				me.didLoadImage(image);
			};
			image.src = me.src;
		}

		/// @param aImage: HTMLImageElement
		, didLoadImage: function(aImage) {
			var me = this, targetSize, image, dialog;

			targetSize = {
				x: me.options.size.width,
				y: me.options.size.height
			};
			image = ns.canvas.draw(null, aImage, {
				orientation: me.options.orientation
			,	maxPixels: ns.canvas.OptimalMaxPixels
			,	allowTransparency: me.options.allowTransparency
			});
			
			dialog = new ns.CropDialog(image, targetSize, me.options.orientation);
			dialog.didComplete = function(cropInfo) {
				me.cropImage(image, cropInfo);
			};
			dialog.didCancel = function() {
				// キャンセルされたときは何もしない
			};
			dialog.show();
		}

		, cropImage: function(srcImage, cropInfo) {
			var me = this, destImage;

			cropInfo.allowTransparency = me.options.allowTransparency;

			destImage = ns.canvas.draw(null, srcImage.canvas, cropInfo);
			srcImage = null; // メモリ解放
			if (typeof me.callback === "function") {
				me.callback(destImage);
			}
			destImage = null; // メモリ解放
		}

	};

	ns.Cropper = Cropper;

})(zzl.uploader);
